import type { LocaleObject as LOUglified } from '@nuxtjs/i18n';
import { Locale } from '~/modules/02-i18n-config-build';

const stateKey = 'regionRequestSettingCode';
type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

type LocaleObject = Prettify<LOUglified>;

type RegionLocalePropertiesStateUglified = LocaleObject | Locale['nuxtI18n'];

type RegionLocalePropertiesState = Prettify<RegionLocalePropertiesStateUglified & { currencyCode?: string }>;

type UseRegionLocalePropertiesStateArgUglified = RegionLocalePropertiesState | undefined;

type UseRegionLocalePropertiesStateArg = Prettify<UseRegionLocalePropertiesStateArgUglified>;

export function useRegionLocaleProperties(localeProperties: UseRegionLocalePropertiesStateArg = undefined) {
  const regionLocaleProperties = useState<RegionLocalePropertiesState>(stateKey);
  if (localeProperties) regionLocaleProperties.value = localeProperties;
  return regionLocaleProperties;
}
