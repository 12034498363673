/* eslint-disable camelcase */
import { getCookie } from './storage';
import { contentstackRequest } from './contentstack/api';
import { getFirstIndex } from './arrays';
import { QueryTypes } from '~/server/utils/contentstack';
import { useCartStore } from '~/store/cart';
import { DYContext, DyChooseReq, EngagementType } from '~/types/dy-types';

declare global {
  interface Window {
    DY: { [index: string]: any };
    dyUserEmail: string;
  }
}

export async function engagementEvent(
  eventType: EngagementType,
  slotId?: string,
  eventProperties?: any
): Promise<void> {
  try {
    const _dyid_server = getCookie('_dyid_server');
    const _dyid = getCookie('_dyid');
    const _dyjsession = getCookie('_dyjsession');
    const user = {
      ...(_dyid && { dyid: _dyid }),
      ...(_dyid_server && { dyid_server: _dyid_server }),
    };
    const session = {
      ...(_dyjsession && { dy: _dyjsession }),
    };
    const reqBody = {
      user,
      session,
      engagements: [] as any[],
    };
    if (eventType === 'CLICK') {
      // used for custom click events
      reqBody?.engagements?.push({
        type: 'CLICK',
        ...eventProperties,
      });
    } else if (eventType === 'SLOT_CLICK') {
      // used for recs
      reqBody?.engagements?.push({
        type: eventType,
        slotId,
      });
    }

    // no-cache
    const data: any = await $fetch.raw('/dy-user-engagement', {
      baseURL: '/api',
      method: 'POST',
      body: JSON.stringify(reqBody),
    });

    if (data?.status !== 204)
      throw new Error(`Engagement responded with non 204 status: ${data?.status || 'no status'}`);
  } catch (error: any) {
    console.warn(error); // eslint-disable-line no-console
  }
}

export function setDYContext(type: string, data: string[] | undefined) {
  window.DY = window.DY || {};
  window.DY.recommendationContext = { type, data };
}

export function getDYContext(url: string, homepage: boolean | undefined): DYContext {
  let context: DYContext = 'OTHER';
  switch (true) {
    case homepage:
      context = 'HOMEPAGE';
      break;
    case url.includes('/plp/'):
    case url.includes('/c/'):
      context = 'CATEGORY';
      break;
    case url.includes('/p/') && !url.includes('/community/'):
      context = 'PRODUCT';
      break;
    case url.includes('/cart'):
      context = 'CART';
      break;
    default:
      context = 'OTHER';
      break;
  }
  return context;
}

export function pageTypeByUrl(urlUsed = '', localeUsed = '') {
  const url = urlUsed?.toLowerCase() || '';
  const locale = localeUsed?.toLowerCase() || '';
  const splitUrl = url.split('/');
  const preChecker = url.split('.');

  if (preChecker[preChecker.length - 1] === 'js') {
    return;
  }
  if (splitUrl[0] === '') splitUrl[0] = '/';
  const urlFilter = splitUrl.filter((item) => item !== '' && item !== locale);

  const isHomepage = urlFilter.length === 1 && urlFilter[0] === '/';
  const subPath = urlFilter.length > 1 ? urlFilter[1] : '';
  let pageType = 'OTHER';
  switch (true) {
    case isHomepage:
      pageType = 'HOMEPAGE';
      break;
    case subPath === 'plp':
    case subPath === 'c':
      pageType = 'CATEGORY';
      break;
    // case url.includes('/p/') && !url.includes('/community/'):
    case subPath === 'p':
      pageType = 'PRODUCT';
      break;
    case subPath === 'cart':
      pageType = 'CART';
      break;
    case preChecker[preChecker.length - 1] === 'js':
      pageType = 'NONE';
      break;
    default:
      pageType = 'OTHER';
      break;
  }
  return pageType;
}

export async function prepData(
  context: DYContext,
  searchParams: URLSearchParams,
  url: string,
  langCode: string
): Promise<string[] | undefined> {
  if (context === 'HOMEPAGE') return [''];
  let returnData: any;
  switch (context) {
    case 'CATEGORY': {
      if (url.includes('/plp/')) {
        const ctsData = await contentstackRequest(QueryTypes.plp, `/plp/${url.split('/').at(-1)}`, {
          query: {
            locale: langCode,
          },
        });
        if (!ctsData) {
          returnData = [];
        }
        const plp = getFirstIndex((ctsData as any)?.data?.all_product_landing_page?.items);
        if (!plp && !plp?.title) {
          returnData = [];
        }
        returnData = plp?.title ? [plp.title] : [];
      } else if (url.includes('/c/')) {
        const ctsData = await contentstackRequest(QueryTypes.clp, `/c/${url.split('/').at(-1)}`, {
          query: {
            locale: langCode,
          },
        });
        if (!ctsData) {
          returnData = [];
        }
        const clp = getFirstIndex((ctsData as any)?.data?.all_category_landing_page?.items);
        if (!clp && !clp?.display_title) {
          returnData = [];
        }
        returnData = clp?.display_title ? [clp.display_title] : [];
      }

      break;
    }
    case 'PRODUCT': {
      const sku = searchParams.get('sku');
      returnData = [sku];
      break;
    }
    case 'CART': {
      const cartStore = useCartStore();
      const products = cartStore.filteredProducts;
      returnData = products?.map((product: any) => product.sku) || [''];
      break;
    }
    default:
      returnData = [];
      break;
  }
  return returnData;
}

export function sha256Email(email: string | undefined): Promise<string> | undefined {
  if (email) {
    const encoder = new TextEncoder();
    const data = encoder.encode(email.toLowerCase().trim());
    return crypto.subtle.digest('SHA-256', data).then((buffer) => {
      return Array.from(new Uint8Array(buffer))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
    });
  }
}

export function setDYUserEmail(email: string | undefined) {
  if (email) {
    window.dyUserEmail = email;
  }
}

export function dyCartData({ cartProducts = [] as any[], isClient = true }): { cartData: any[]; skuData: any[] } {
  const cartData: any[] = [];
  const skuData: any[] = [];
  cartProducts?.forEach((cartProduct: any) => {
    if (+(cartProduct?.price?.regular || 0) > 0) {
      if (isClient)
        cartData.push({
          productId: cartProduct.sku,
          quantity: cartProduct.qty,
          itemPrice: cartProduct?.price?.regular,
        });
      skuData.push(cartProduct.sku);
    }
  });
  return { cartData, skuData };
}

export async function dyChooseCall(langCode: string, isCart = false) {
  try {
    const event = useRequestEvent();
    const URL = useRequestURL();
    const searchParams = new URLSearchParams(URL.search.replace('?', ''));
    const dyApiPreview = searchParams.get('dyApiPreview');
    const homepage = URL?.pathname === '/' + langCode;
    const context = isCart ? 'CART' : getDYContext(URL.toString(), homepage);
    const userAgent = process.server ? event?.headers?.get('User-Agent') : navigator?.userAgent;
    const data = await prepData(context, searchParams, URL.toString(), langCode);
    const selectorGroups = ['headless-site'];
    const reqBody = {
      selector: {
        groups: selectorGroups,
        preview: { ids: [dyApiPreview] },
      },
      options: {
        isImplicitPageview: false,
        returnAnalyticsMetadata: true,
      },
      context: {
        page: {
          type: context,
          data,
          location: URL || '',
        },
        device: (userAgent && { userAgent }) || { userAgent: '' },
      },
      user: {},
      session: {},
    };

    if (process.server) {
      const dyIdServerCookie = useCookie('_dyid_server');
      const dyIdCookie = useCookie('_dyid');
      const dySessionCookie = useCookie('_dyjsession');
      const user = {
        dyid: dyIdCookie?.value || '',
        dyid_server: dyIdServerCookie?.value || '',
      };
      const session = {
        dy: dySessionCookie?.value || '',
      };

      reqBody.user = user;
      reqBody.session = session;
    }

    if (process.client) {
      const dyIdServerCookie = getCookie('_dyid_server');
      const dyIdCookie = getCookie('_dyid');
      const dySessionCookie = getCookie('_dyjsession');

      const user = {
        dyid: dyIdCookie || '',
        dyid_server: dyIdServerCookie || '',
      };
      const session = {
        dy: dySessionCookie || '',
      };

      reqBody.user = user;
      reqBody.session = session;
    }

    const reqUrl = `/dy-choose${dyApiPreview ? `?dyApiPreview=${dyApiPreview}` : ''}`;
    const body = JSON.stringify(reqBody);

    // no-cache
    const resData = await $fetch(reqUrl, {
      method: 'POST',
      baseURL: '/api',
      body,
    });

    return resData || {};
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    return {};
  }
}

// DO NOT MODIFY THIS FUNCTION WITHOUT MODIFYING /webapp/server/utils/dy-workaround.ts
// Duped to avoid breaking HMR, and maybe something else
export function buildChooseReqBody({
  eventBody = {} as any,
  eventHeaders = {} as any,
  eventCookies = {} as any,
  eventQuery = {} as URL['searchParams'],
  config = {} as any,
}) {
  const { _dyid, _dyid_server, _dyjsession } = eventCookies;
  const { dyApiPreview } = eventQuery as unknown as { dyApiPreview?: string };
  const userAgent = eventHeaders?.['user-agent'];
  const ipAddress = eventHeaders?.['cf-connecting-ip'] || eventHeaders?.['x-forwarded-for'];

  const user = {
    ...(_dyid && { dyid: _dyid }),
    ...(_dyid_server && { dyid_server: _dyid_server }),
  };
  const session = { ...(_dyjsession && { dy: _dyjsession }) };
  eventBody.context = {
    // page: {
    //   // type: getDYContext(urlStructure),
    //   type: 'HOMEPAGE',
    //   data: [''],
    //   // location,
    //   location: 'http://localhost:3000/en-us',
    // },
    ...(eventBody?.context || {}),
    device: {
      ...((eventBody.context?.device && { userAgent: eventBody.context?.device }) || {}),
      ...(userAgent && { userAgent }),
      ip: ipAddress,
      // ip: testIpAddresses.newYork,
    },
  };

  const reqBody: DyChooseReq = {
    selector: {
      groups: [...(eventBody?.selector?.groups || ['headless-site'])],
    },
    context: eventBody?.context,
    user,
    session,
    options: {
      isImplicitPageview: false,
      returnAnalyticsMetadata: true,
    },
    ...(eventBody?.user && { user: eventBody.user }),
    ...(eventBody?.session && { session: eventBody.session }),
  };

  if (dyApiPreview) {
    reqBody.selector = {
      ...reqBody.selector,
      preview: {
        ids: [dyApiPreview],
      },
    };
  }

  if (config.public.environment !== 'production') {
    const newGroups =
      reqBody.selector?.groups?.map((group: string) => group.replace('headless-site', 'campaign-test')) || [];
    reqBody.selector.groups = reqBody?.selector?.groups?.concat(newGroups);
  }
  return {
    reqBody,
    _dyid: reqBody.user?.dyid,
    _dyid_server: reqBody.user?.dyid_server,
    _dyjsession: reqBody.session?.dy,
  };
}
