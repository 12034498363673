import { Metadata, MetaParamsContentStack, MetadataItem, MetadataLink } from '@/types/metadata';
import { useCloudinary } from '~/composables/cloudinary';

// https://developers.google.com/search/docs/advanced/crawling/special-tags

/**
 * To avoid any duplication when used in child components,
 * please give a unique identifier with the hid key to the meta description.
 * This way vue-meta will know that it has to overwrite the default tag.
 */
// Optional Param = ...(flag1 && { optionalKey1: 5 }),
export function metaGen(
  brandSettings: any,
  params: MetaParamsContentStack,
  localeItems?: { meta: MetadataItem[]; links: MetadataLink[] }
) {
  const { locales, locale } = useI18n();
  const matchedLocale = (locales.value as any).find((i: any) => i.code === locale.value);
  const defaultSeo = brandSettings?.default_seo?.seo;
  const seoAppend = brandSettings?.default_seo?.seo_title_append;
  const defaultTitle = defaultSeo?.page_title;
  const defaultDescription = defaultSeo?.page_description as string;

  const title =
    (params?.page_title || defaultTitle) +
    (seoAppend ? ` ${seoAppend}` : '') +
    (matchedLocale?.code !== 'en-us' ? ` ${matchedLocale?.name}` : '');
  const ogTitle = params?.open_graph_title || defaultTitle;
  const desc = params?.page_description || defaultDescription;
  const ogDesc = params?.open_graph_description || defaultDescription;

  // Google doesnt care about meta keywords
  const metaObj: Metadata = {
    title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: desc,
      },
      {
        hid: 'og:title',
        name: 'og:title',
        content: ogTitle,
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: ogDesc,
      },
      // og:url
    ],
    link: [],
    script: [],
  };
  if (params?.open_graph_image?.length || params?.ogImage) {
    const contentImg = params?.ogImage || '';
    metaObj.meta.push({
      hid: 'og:image',
      name: 'og:image',
      content: contentImg,
    });
  }
  if (params?.no_index) {
    metaObj.meta.push({
      hid: 'robots',
      name: 'robots',
      content: 'noindex',
    });
  }
  if (params?.canonical) {
    metaObj.link.push({ hid: 'canonical', rel: 'canonical', href: params.canonical });
  }
  if (localeItems) {
    metaObj.meta.push(...localeItems?.meta);
    metaObj.link.push(...localeItems?.links);
  }
  return metaObj;
}

export function getProductRichResults(
  product: any,
  bcProduct: any,
  imagesSwap: any,
  rootStore: any,
  code: string,
  strippedUrl: string,
  currentPrice: number
): any {
  const cloudinary = useCloudinary();
  const mainImgUrl = imagesSwap?.length && imagesSwap?.[0]?.public_id;
  return {
    type: 'application/ld+json',
    // Vue Meta 2.1+ for json property directly
    json: {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: product?.display_title,
      image: [
        cloudinary.generateImageUrl(mainImgUrl, { width: 500, height: 500 }), // 1:1
        cloudinary.generateImageUrl(mainImgUrl, { width: 800, height: 600 }), // 4:3
        cloudinary.generateImageUrl(mainImgUrl, { width: 720, height: 576 }), // 16:9
      ],
      description: product?.description || '',
      sku: bcProduct?.sku,
      // mpn: '925872',
      brand: {
        '@type': 'Brand',
        name: rootStore?.brandSettings?.brand_name,
      },
      // review: {
      //   '@type': 'Review',
      //   reviewRating: {
      //     '@type': 'Rating',
      //     ratingValue: '4',
      //     bestRating: '5',
      //   },
      //   author: {
      //     '@type': 'Person',
      //     name: 'Fred Benson',
      //   },
      // },
      // aggregateRating: {
      //   '@type': 'AggregateRating',
      //   ratingValue: '4.4',
      //   reviewCount: '89',
      // },
      // TODO - hook all of these up and update for locales
      offers: {
        '@type': 'Offer',
        url: strippedUrl,
        priceCurrency: code,
        price: currentPrice,
        // priceValidUntil: '2020-11-20',
        itemCondition: 'https://schema.org/NewCondition',
        availability: 'https://schema.org/InStock',
      },
    },
  };
}
