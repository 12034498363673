import type { TrackEventOptions } from '@gtm-support/core';
import { GtmEventItem } from '~/util/analytics';
import { GeoData } from '~/store/geodata';
import { Customer } from '~/store/customer';

export type MetaEventName = 'PageView' | 'AddToCart' | 'ViewContent' | 'InitiateCheckout';
export type MetaTrackEventOptions = TrackEventOptions & {
  contentName?: string;
  eventItems?: GtmEventItem[];
  currencyCode?: string;
  geoData?: GeoData;
  event: MetaEventName;
};
export interface MetaIdentifyData extends GeoData, Customer {}
export interface MetaIdentity {
  em?: string;
  fn?: string;
  ln?: string;
  ph?: string;
  ct?: string;
  st?: string;
  zp?: string;
  country?: string;
  client_ip_address?: string;
  client_user_agent?: string;
}

export interface MetaBaseEvent {
  /** Category of the page or product. */
  content_category?: string;

  /** Product IDs associated with the event, such as SKUs. Example: ['ABC123', 'XYZ789']. */
  content_ids?: Array<string | number>;

  /** Name of the page/product. */
  content_name?: string;

  /**
   * Can be 'product' or 'product_group' based on the content_ids or contents being passed.
   * If the IDs being passed in the content_ids or contents parameter are IDs of products, then the value should be 'product'.
   * If product group IDs are being passed, then the value should be 'product_group'.
   */
  content_type?: 'product' | 'product_group';

  /**
   * Array of JSON objects that contains the International Article Number (EAN) when applicable or other product or content identifier(s) associated with the event,
   * and quantities and prices of the products. Required: id and quantity.
   * Example: [{'id': 'ABC123', 'quantity': 2}, {'id': 'XYZ789', 'quantity': 2}].
   */
  contents?: Array<{ id: string; quantity: number; price?: number; ean?: string }>;

  /** Currency for the value specified. */
  currency?: string;

  /**
   * Category of the delivery. Supported values:
   * 'in_store' - Purchase requires customer to enter the store.
   * 'curbside' - Purchase requires curbside pickup.
   * 'home_delivery' - Purchase is delivered to the customer.
   */
  delivery_category?: 'in_store' | 'curbside' | 'home_delivery';

  /** Number of items when checkout was initiated. Used with the InitiateCheckout event. */
  num_items?: number;

  /** Predicted lifetime value of a subscriber as defined by the advertiser and expressed as an exact value. */
  predicted_ltv?: number;

  /** String entered by the user for the search. Used with the Search event. */
  search_string?: string;

  /** Value of a user performing this event to the business. */
  value?: number;

  /** Additional custom properties. */
  [index: string]:
    | string
    | number
    | boolean
    | Array<string | number>
    | Array<{ id: string; quantity: number; price?: number; ean?: string }>
    | undefined;
}

// Specific event types

export interface MetaCompleteRegistrationEvent extends MetaBaseEvent {
  /** Used with the CompleteRegistration event, to show the status of the registration. */
  status: boolean;
}

export interface MetaInitiateCheckoutEvent extends MetaBaseEvent {
  /** Number of items when checkout was initiated. Used with the InitiateCheckout event. */
  num_items: number;
}

export interface MetaSearchEvent extends MetaBaseEvent {
  /** String entered by the user for the search. Used with the Search event. */
  search_string: string;
}

export interface MetaApiData {
  event_name: string;
  event_time: number;
  action_source: string;
  event_id: string;
  event_source_url: string;
  user_data: MetaIdentity;
  custom_data: MetaBaseEvent | MetaCompleteRegistrationEvent | MetaInitiateCheckoutEvent | MetaSearchEvent;
}

export const metaGetIdentifyData = function (data: MetaIdentifyData) {
  const identify: {
    em?: string;
    fn?: string;
    ln?: string;
    ph?: string;
    ct?: string;
    st?: string;
    zp?: string;
    country?: string;
    client_ip_address?: string;
    client_user_agent?: string;
    fbp?: string;
    fbc?: string;
  } = {};
  let fbp, fbc;
  if (data?.location?.city) identify.ct = data.location.city;
  if (data?.location?.regionCode) identify.st = data.location.regionCode;
  if (data?.location?.postalCode) identify.zp = data.location.postalCode;
  if (data?.location?.country) identify.country = data.location.country;
  if (data.ip) identify.client_ip_address = data.ip;
  if (data.email) identify.em = data.email;
  if (data.first_name) identify.fn = data.first_name;
  if (data.last_name) identify.ln = data.last_name;
  if (data.phone) identify.ph = data.phone;
  if (process.client) {
    fbp = document.cookie.split('; ').find((row) => row.startsWith('_fbp'));
    fbc = document.cookie.split('; ').find((row) => row.startsWith('_fbc'));
    fbp = fbp && fbp.length > 0 ? fbp.split('=')[1] : undefined;
    fbc = fbc && fbc.length > 0 ? fbc.split('=')[1] : undefined;
    if (fbp) identify.fbp = fbp;
    if (fbc) identify.fbc = fbc;
  }
  if (navigator?.userAgent) identify.client_user_agent = navigator.userAgent;
  return identify;
};

/**
 * @param _event MetaEventName
 * @param _product GtmEventItem
 * @returns string
 * @deprecated
 */
export const metaGetProductContenttype = function (_event: MetaEventName, _product: GtmEventItem) {
  return 'product'; // this should always be product, since Adminds has indicated we will not be passing parent IDs.
  // const hasVariants = product.item_variant !== '';
  // if (event === 'AddToCart') return 'product';
  // return hasVariants ? 'product_group' : 'product';
};

export const metaGetEventValue = function (event: MetaEventName, eventItems: GtmEventItem[]): number | undefined {
  if (!eventItems || eventItems.length === 0) return undefined;
  return eventItems.reduce((acc, item) => acc + (item.price || 0), 0);
};

export const metaGetEventIDs = function (eventItems: GtmEventItem[]) {
  return eventItems.map((item) => item.item_id);
};

export const metaGetEventData = function (eventOptions: MetaTrackEventOptions) {
  const { event, contentName, eventItems, currencyCode, geoData } = eventOptions;
  if (!event) throw new Error('Event name is required');
  const isPageView = event === 'PageView';
  const identifyData = metaGetIdentifyData(geoData as MetaIdentifyData);
  const contentType = !isPageView ? 'product' : undefined;
  // const contentName = eventItems[0].item_name;
  const ids = metaGetEventIDs(eventItems || []);
  const contentIds = ids.length > 0 ? ids : undefined;
  const value = metaGetEventValue(event as MetaEventName, eventItems || []);
  const fbProduct: MetaBaseEvent = {
    content_name: contentName,
    content_ids: contentIds,
    content_type: contentType as MetaBaseEvent['content_type'],
    value,
    ...(!isPageView && currencyCode && { currency: currencyCode }),
  };

  // Unique event ID for deduplication
  const timestamp = Date.now();
  const fbEventTime = Math.floor(timestamp / 1000);
  const fbEventId = `${timestamp}`;

  // Need to provide noscript params for the image pixel
  let noscriptImgQueryParams = '';
  if (contentName) noscriptImgQueryParams += `content_name=${encodeURIComponent(contentName)}&`;
  if (ids.length > 0) noscriptImgQueryParams += `content_ids=${encodeURIComponent(ids.join(','))}&`;
  if (contentType) noscriptImgQueryParams += `content_type=${encodeURIComponent(contentType)}&`;
  if (value) noscriptImgQueryParams += `value=${encodeURIComponent(value)}&`;
  if (!isPageView && currencyCode) noscriptImgQueryParams += `currency=${encodeURIComponent(currencyCode)}&`;
  if (fbEventId) noscriptImgQueryParams += `eventID=${encodeURIComponent(fbEventId)}&`;
  if (noscriptImgQueryParams.endsWith('&')) {
    noscriptImgQueryParams = noscriptImgQueryParams.slice(0, -1);
  }
  noscriptImgQueryParams += '&noscript=1';

  const apiData: MetaApiData = {
    event_name: '',
    event_time: fbEventTime,
    action_source: 'website',
    event_id: fbEventId,
    event_source_url: window.location.href,
    user_data: {
      ...identifyData,
    },
    custom_data: fbProduct,
  };
  const pixelData = { ...fbProduct, noscriptImgQueryParams };
  return { pixelData, apiData };
};
