import validate from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/nuxt/dist/pages/runtime/validate.js";
import dy_45global from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/middleware/dy.global.ts";
import manifest_45route_45rule from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  dy_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "bc-cart-validate": () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/middleware/bc-cart-validate.ts"),
  connection: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/middleware/connection.ts")
}