import { useFeatureStore } from '~/store/feature';
import { useNavStore } from '~/store/nav';
import { useTestStore } from '~/store/test';
import { dyCartData, dyChooseCall } from '~/util/dy';
import { useCartStore } from '~/store/cart';
import { AddToCartItem } from '~/types/bigcommerce';
import { useLocaleStore } from '~/store/locale';

declare global {
  interface Window {
    dyChoicesDataJson: any;
    DY: { [index: string]: any };
  }
}

type DyCampaign = {
  campaignName: string;
  cookieName: string;
  payloadName: string;
  altName?: string;
  store: string;
  action: string;
  action2?: string;
};

interface DyState {
  pageContextSet: boolean;
  dyChoices: any[];
  recChoices: any[];
  dyBackCompat: DyCampaign[];
}

export const useDyStore = defineStore('dy', {
  state: (): DyState => ({
    pageContextSet: false,
    dyChoices: [],
    recChoices: [],
    dyBackCompat: [
      // START - NAV TESTS
      {
        campaignName: 'Nav Test - Hide Top Levels',
        cookieName: 'dy_nav_test_hide_top_levels',
        payloadName: 'topNavTargets',
        store: 'nav',
        action: 'modifyHeadMainNavs',
      },
      {
        campaignName: 'CLP/PLP Nav Test',
        cookieName: 'dy_clp_plp_test',
        payloadName: 'clpPlpTest',
        store: 'nav',
        action: 'setClpPlpTest',
      },
      // END - NAV TESTS
      {
        campaignName: 'Gift With Purchase',
        cookieName: 'dy_gift_with_purchase_test',
        payloadName: 'giftWithPurchaseTest',
        store: 'test',
        action: 'setGiftWithPurchaseTest',
      },
    ],
  }),

  getters: {
    getFeatureFlagDecisions: (state: any) =>
      state.dyChoices.filter((choice: any) => {
        const filteredVariations = choice.variations?.filter(
          (variation: any) => variation.payload?.data?.type === 'feature-flag'
        );
        return filteredVariations?.length > 0;
      }),
    // recDesisions: (state: any) => state.dyChoices.filter((choice: any) => choice.type === "RECS_DECISION"),
    // decisions: (state: any) => state.dyChoices.filter((choice: any) => choice.type === "DECISION"),
    // decisionNames: (state: any, getters: any) => getters.decisions.map((choice: any) => choice.name),
    // customCampaigns: (state: any) => {
    //   const customCampaigns = state.dyChoices.filter((choice: any) => {
    //     const filteredVariations = choice.variations?.filter((variation: any) => variation.payload?.type === "CUSTOM_JSON");
    //     return filteredVariations?.length > 0;
    //   });
    //   return customCampaigns;
    // },
    // customCampaignNames: (state: any, getters: any) => getters.customCampaigns.map((choice: any) => choice.name),
    // disabledCampaigns: (state: any, getters: any) => {
    //   return state.dyChoices.filter((choice: any) => choice.variations?.length === 0 && getters.decisionNames.includes(choice.name));
    // },
    // disabledCampaignNames: (state: any, getters: any) => getters.disabledCampaigns.map((choice: any) => choice.name),
  },

  actions: {
    setPageContext(pageContextSet: any) {
      this.pageContextSet = pageContextSet;
    },
    setDyChoices(data: any) {
      this.dyChoices = data;
    },
    setRecChoices(data: any) {
      this.recChoices = data;
    },
    // dyDataExtractionEdge() { // TODO: refactor & put into nuxt server init
    //   if (window?.dyChoicesDataJson) {
    //     this.setDyChoices(window.dyChoicesDataJson);
    //     delete window.dyChoicesDataJson;
    //   }
    // },
    dyDataExtraction(choices: any[]) {
      this.setDyChoices(choices);
    },
    async trackAddToCartEvent(data: AddToCartItem[]) {
      try {
        const cartStore = useCartStore();
        const cartProducts = cartStore.products;
        const parsedData = [];

        for (let i = 0; i < data?.length || 0; i++) {
          const id = data[i].product?.id;
          const product = cartProducts?.find((product: any) => {
            return product.variant_id === id || product.id === id;
          });
          if (product) parsedData.push(product);
        }

        const { cartData } = dyCartData({ cartProducts });

        const { langCode } = useLocaleStore();
        await this.updateRecChoices(langCode, true);

        parsedData.forEach((product: any) => {
          const quantity = product?.qty || 0;
          const price = +(product?.price?.regular || 0);
          const totalValue = quantity * price;
          const sku = product?.sku || '';

          // TODO: Review - May need to use apiLayer for call here depending on what could be missed by deferring the DY scripts
          if (totalValue && totalValue > 0) {
            window?.DY?.API('event', {
              name: 'Add to Cart',
              properties: {
                dyType: 'add-to-cart-v1',
                value: totalValue, // was store.getters['cart/totalPrice'], but docs make it seem like it should just be the total of the current item * qty
                // TODO - currencyCode
                currency: 'USD',
                productId: sku, // Do not change to ID - this needs to be the SKU
                quantity,
                cart: cartData,
              },
            });
          }
        });
      } catch (error: any) {
        console.error(error); // eslint-disable-line no-console
      }
    },
    async updateDyChoices(langCode: string) {
      // TODO: re-think usage of process.env and a better way around it
      const newDyData: any = await dyChooseCall(langCode);
      this.fullCallMutation(newDyData);
    },
    async updateRecChoices(langCode: string, isCart = false) {
      const data: any = await dyChooseCall(langCode, isCart);
      this.setRecChoices(data?.choices || this.recChoices);
    },
    fullCallMutation(newDyData: any) {
      const recData: any = [];
      const choiceData: any = [];

      newDyData?.choices?.forEach((choice: any) => {
        if (choice.type === 'RECS_DECISION') {
          recData.push(choice);
        } else {
          choiceData.push(choice);
        }
      });

      this.setDyChoices(choiceData?.length > 0 ? choiceData : this.dyChoices);
      this.setRecChoices(recData?.length > 0 ? recData : this.recChoices);

      // if (process.server) { // TODO: DEBUGGING
      //   console.log('hitting dy store');
      // }
      if (process.client) {
        this.iterateBackCompatDecisions();
        const featureStore = useFeatureStore();
        featureStore.setEnabledStatus();
      }
    },
    iterateBackCompatDecisions() {
      const navStore = useNavStore();
      const testStore = useTestStore();
      const storeWrapper: any = {
        nav: {
          setClpPlpTest: navStore.setClpPlpTest,
          modifyHeadMainNavs: navStore.modifyHeadMainNavs,
        },
        test: {
          setGiftWithPurchaseTest: testStore.setGiftWithPurchaseTest,
        },
      };
      this.dyBackCompat.forEach((backCompat: any) => {
        try {
          const choice = this.dyChoices.find((choice: any) => choice.name === backCompat.campaignName);
          const variations = choice?.variations || [];
          const variation = variations?.[0];
          const data = variation?.payload?.data;
          const payloadSimpleValue = data?.[backCompat.payloadName];
          if (!backCompat.action) return;
          const stopChecker = variations.length === 0 || typeof payloadSimpleValue === 'undefined';
          // Note: Let these fail, we can fix or remove items that are problematic and can throw an error
          // Not letting this fail will hide problems
          // Do not optional chain anything past this point
          const storeAccess = storeWrapper[backCompat.store];

          if (stopChecker && !backCompat.stopEmptyOverwrite) {
            const storeAccess = storeWrapper[backCompat.store];
            storeAccess[backCompat.action](false);
            // console.log(`actioning ${backCompat.action} with false`);
            return;
          }
          if (stopChecker) return;

          storeAccess[backCompat.action](payloadSimpleValue);
          // console.log(`actioning ${backCompat.action} with ${JSON.stringify(payloadSimpleValue)}`);
        } catch (e) {
          let errorMessage = `Store action error while attempting ${backCompat.action}`;
          errorMessage += `: ${e}`;
          console.error(errorMessage); // eslint-disable-line no-console
        }
      });
    },
    findByCampaignId({
      campaignId,
      variationId = '',
      skipVariation = true,
    }: {
      campaignId: string;
      variationId?: string;
      skipVariation?: boolean;
    }) {
      for (let i = 0; i < this.dyChoices.length; i++) {
        const variations = this.dyChoices[i].variations || [];
        for (let choicePayloadIndex = 0; choicePayloadIndex < variations.length; choicePayloadIndex++) {
          const payload = variations[choicePayloadIndex].payload;
          if (
            payload?.data?.cts_campaign_id === campaignId &&
            (payload?.data?.cts_variation_id === variationId || skipVariation)
          ) {
            return {
              ...this.dyChoices[i],
              ...(!skipVariation && { variationInfo: payload?.data || {} }),
            };
          }
        }
      }
      return { noMatch: true }; // hax - // TODO: fix feature store to refactor this
    },
  },
});
