import { CartState } from '~/store/cart';
export function checkIsDiscountFree(product: any): boolean {
  if (product?.discounts?.length) {
    const freeItemIdx = product.discounts.findIndex(
      (item: any) =>
        item?.discounted_amount === 0 ||
        item?.discounted_amount === product?.price?.msrp * product?.qty ||
        product?.price?.regular === 0
    );
    if (freeItemIdx !== -1) {
      return true;
    }
  }
  return false;
}

export function filterGwpProducts(
  cartProducts: CartState['products'],
  gwpProducts: any[],
  giftWithPurchaseTest: boolean
): any[] {
  const noBoxProducts = filterBoxProducts(cartProducts);
  return giftWithPurchaseTest
    ? noBoxProducts?.filter((prod: any) => {
        const gwpMatch = gwpProducts?.findIndex((gwpProd: any) => {
          return (
            prod?.id === gwpProd?.product?.product_offerings?.bc_primary_product?.product?.id &&
            checkIsDiscountFree(prod)
          );
        });
        return gwpMatch === -1;
      })
    : noBoxProducts;
}

/**
 *
 * @param cartProducts BC cart products
 * @param bcProduct current bc product (base product)
 * @param quantity selected quantity
 * @param maxQuantity CTS max Quantity (should match BC rule)
 * @returns true if the desired added amount exceeds limits
 */
export function maxCartProducts(
  cartProducts: any,
  bcProduct: any,
  quantity: number,
  maxQuantity: number | null | undefined
) {
  // Find the same base products
  const matchingCartProducts = cartProducts?.filter((prod: any) => prod?.id === bcProduct?.id);
  let total = 0 + quantity;
  if (matchingCartProducts?.length) {
    matchingCartProducts.forEach((item: any) => {
      total += item?.qty;
    });
  }
  return maxQuantity && matchingCartProducts?.length ? total > maxQuantity : false;
}

export function filterBoxProducts(cartProducts: any[]) {
  return cartProducts?.filter((prod: any) => {
    return !prod.sku?.match(/-BOX$/) || prod.sku?.match(/WHISTLE-PIG-WHISKEY-BOX/);
  });
}

export function filterHiddenProducts(cartProducts: any[]) {
  return cartProducts?.filter((product: any) => {
    return (
      // a product added on the BC-side, should be hidden
      // when viewing the cart on the Solo-side
      !/(Route Package Protection)/i.test(product.title)
    );
  });
}

/**
 *
 * @param cartProducts BC cart products
 * @param bcProduct current bc product (base product)
 * @returns totalCart count of that base product
 */
export function numBaseProductsInCart(cartProducts: any, bcProduct: any) {
  // Find the same base products
  const matchingCartProducts = cartProducts?.filter((prod: any) => prod?.id === bcProduct?.id);
  let total = 0;
  if (matchingCartProducts?.length) {
    matchingCartProducts.forEach((item: any) => {
      total += item?.qty;
    });
  }
  return total;
}

export function findEtchingSurcharge(item: any) {
  // Nuxt didn't like something, so abstracted to util
  const surchargeConfig = item?.configuration?.find((itemConfig: any) => {
    const name = itemConfig?.name?.toLowerCase() || '';
    return name?.includes('etching') && name?.includes('surcharge');
  });
  const preCheckedValue = +surchargeConfig?.value;
  const isNaN = Number.isNaN(preCheckedValue);
  return isNaN ? 0 : preCheckedValue;
}

export function getBackOrderMessage(product: any, variantSku: string) {
  let optionIndex = 0;
  product.product_offerings?.product_variations.forEach(function callback(variant: any, index: number) {
    if (variant.bc_variant.sku === variantSku) optionIndex = index;
  });
  const variations = product.product_offerings.product_variations;
  const allVariants = optionIndex !== -1 && !!variations ? variations : null;
  const selectedVariant = allVariants?.[optionIndex];
  const message = selectedVariant.messaging?.backorder_message || '';
  return message;
}
