import { ProductVariantOptionValue, Option } from '@solo-stove/types/bigcommerce';
import { useLocaleStore } from '~/store/locale';
import { useProductDataStore } from '~/store/product-data';
import { AddToCartItem } from '~/types/bigcommerce';
import { useCloudinary } from '~/composables/cloudinary';
import { getCookie, CookieKeys } from '~/util/storage';

const cloudinary = useCloudinary();
/**
 * Adminds defined ecom events
 */
export enum GtmEventType {
  addPaymentInfo = 'add_payment_info',
  addShippingInfo = 'add_shipping_info',
  addToCart = 'add_to_cart',
  beginCheckout = 'begin_checkout',
  purchase = 'purchase',
  removeFromCart = 'remove_from_cart',
  selectItem = 'select_item',
  viewCart = 'view_cart',
  viewItem = 'view_item',
  viewItemList = 'view_item_list',
  searchClickItem = 'search_click_item',
  search = 'search',
  login = 'login',
  accountCreation = 'account_creation',
}

export interface GtmEventItem {
  item_id: string;
  item_name: string;
  item_brand: string;
  price: number | undefined;
  original_price: number | undefined;
  item_variant: string | undefined;
  item_category: string | undefined;
  item_list_name: string;
  item_image?: string;
  quantity?: number;
  index?: number;
  item_parent_id?: string;
}

export interface BaseEvent {
  event: string;
  data: { [key: string]: unknown };
}

export interface BaseEcommerceEvent extends BaseEvent {
  data: { ecommerce: { items: GtmEventItem[]; currency: string; cart_url?: string } };
}

export function baseEventStructure(eventType: string, data: unknown): BaseEvent {
  const base = {
    event: eventType,
    data: {} as { [key: string]: unknown },
  };
  base.data[eventType] = data;
  return base;
}

export function baseEcommerceEventStructure(props: {
  eventType: GtmEventType;
  items: GtmEventItem[];
}): BaseEcommerceEvent {
  const localeStore = useLocaleStore();
  const config = useRuntimeConfig();
  const soloUserCookie = CookieKeys.Cart;
  const cartToken = getCookie(soloUserCookie);
  const cartUrl = cartToken ? `${config.public.baseUrl}/cart?token=${cartToken}` : undefined;
  const data = {
    ecommerce: { items: props.items, currency: localeStore.currencyCode, ...(cartUrl && { cart_url: cartUrl }) },
  };
  const base = baseEventStructure(props.eventType, data) as BaseEcommerceEvent;
  return base;
}

export function mapCtsCartItemsToGtmCartItems(cartItems: AddToCartItem[] = []): GtmEventItem[] {
  return cartItems.map((item: AddToCartItem, i: number) => {
    if (item.product.gift_certificates) return mapCartGiftCardToGtmCartItems(item.product);
    const priceData = getPriceData(item.product.sku);
    const mappedItem = {
      index: i + 1, // they want to start at 1
      price: priceData?.price,
      original_price: priceData?.original_price,
      item_id: item.product.sku,
      quantity: item.quantity,
      item_name: item.product?.name,
      item_brand: item.product?.brand,
      item_image: item.product?.image,
      item_variant: item.hasVariants === true ? getVariantName(item) : '',
      item_category: item.product?.category,
      // item_list_name: '', // TODO - pi accessories
      item_parent_id: item.product?.parentSku,
    };
    return mappedItem as GtmEventItem;
  });
}

export function mapCartGiftCardToGtmCartItems(product: any): GtmEventItem {
  const giftCards = product.gift_certificates;
  return giftCards.map((giftCard: any, i: number) => {
    // not passed as part of product, but uses static values for basic giftcard
    const itemCategory = 'Gift Card';
    const giftcardBrand = giftCard.name === 'Solo Stove Digital Gift Card' ? 'Solo Stove' : '';
    const mappedItem = {
      index: i + 1, // they want to start at 1
      price: giftCard.amount,
      item_id: product.sku,
      item_brand: giftcardBrand,
      item_image: product.image,
      item_category: itemCategory,
      quantity: giftCard.quantity,
      item_name: giftCard.name,
    };
    return mappedItem as GtmEventItem;
  });
}

export function mapCtsCartItemsToGtmEvent(eventType: GtmEventType, cartItems: AddToCartItem[]) {
  return baseEcommerceEventStructure({ eventType, items: mapCtsCartItemsToGtmCartItems(cartItems) });
}

export function mapBCCartItemsToGtmCartItems(cartItems: any[] = []): GtmEventItem[] {
  const gaProducts = cartItems?.map((product) => {
    return {
      ...product,
      sku: product.sku,
      quantity: product?.quantityOverride || product.qty,
    };
  });
  const GtmEventItem = gaProducts?.map((item) => {
    // console.log('gtm bc cart item pre map', item);
    const priceData = getPriceData(item?.sku);
    const mappedItem = {
      price: priceData?.price,
      original_price: priceData?.original_price,
      item_id: item?.sku,
      quantity: item?.quantity,
      item_name: item?.title,
      item_brand: item?.brand,
      item_variant: getBcVariantName(item?.configuration),
      item_category: '', // TODO - pizza oven
      item_list_name: '', // TODO - pi accessories
      item_image: cloudinary.generateImageUrl(item?.image, { width: 550, height: 550 }),
    };
    // console.log('gtm bc cart item post map', mappedItem);
    return mappedItem;
  });
  return GtmEventItem;
}

export function mapBcCartItemsToGtmEvent(eventType: GtmEventType, cartItems: any[]) {
  return baseEcommerceEventStructure({ eventType, items: mapBCCartItemsToGtmCartItems(cartItems) });
}

export function mapProductCardsItemsToGtmEvent(eventType: GtmEventType, cartItems: AddToCartItem[]) {
  const GtmEventItem = cartItems?.map((item: AddToCartItem, i: number) => {
    // console.log('gtm product card item pre map', item);
    // @ts-expect-error
    const hasVariant = item?.variant?.option_values?.length;
    const sku = item?.variant?.sku || item?.product?.sku;
    return {
      index: item?.index ? item.index + 1 : i + 1, // they want to start at 1
      item_id: sku,
      ...getPriceData(sku),
      quantity: item?.quantity,
      item_name: (item?.product as any)?.name,
      item_brand: item?.product?.brand,
      item_variant: hasVariant ? getVariantName(item) : '',
      // @ts-expect-error
      item_category: (item.product?.product_taxonomy?.category || []).join(', '),
      item_list_name: '', // TODO - pi accessories
    };
  });
  // console.log('gtm product card item post map', GtmEventItem);
  // @ts-expect-error
  return baseEcommerceEventStructure({ eventType, items: GtmEventItem });
}

export function mapAlgoliaSearchClickToGtmEvent(eventType: GtmEventType, searchItem: any) {
  // console.log('gtm algolia search item pre map', searchItem);
  const GtmSearchEventItem = {
    item_id: searchItem.productSKU,
    item_url: searchItem.productURL,
    price: searchItem.finalPrice,
    positionOfTile: searchItem.positionOfTile,
    totalTiles: searchItem.totalTiles,
    // original_price: searchItem.originalPrice,
  };
  const base = baseEventStructure(eventType, GtmSearchEventItem) as BaseEcommerceEvent;
  return base;
}

export function mapAlgoliaItemsToGtmEvent(eventType: GtmEventType, cartItems: any[]) {
  const GtmEventItem = cartItems?.map((item: any, i: number) => {
    // console.log('gtm algolia item pre map', item);
    const hasVariant = item?.option_variants?.variations?.length;
    const sku = hasVariant ? item?.variant?.sku || '' : item?.sku;
    return {
      index: i + 1, // they want to start at 1
      item_id: sku,
      ...getPriceData(sku),
      item_name: item?.product?.name,
      item_brand: item?.product?.brand,
      item_variant: getVariantName(item),
      item_category: '', // TODO - pizza oven
      item_list_name: '', // TODO - pi accessories
    };
  });
  return baseEcommerceEventStructure({ eventType, items: GtmEventItem });
}

export function mapAlgoliaSearchToGtmEvent(eventType: GtmEventType, cartItems: any[]) {
  const GtmEventItem = cartItems?.map((item: any, i: number) => {
    // console.log('gtm algolia search item pre map', item);
    const hasVariant = item?.option_variants?.variations?.length;
    if (hasVariant) {
      // set first variant, algolia shows first variant in search results
      item.variant = item.option_variants.variations[0].bc_variant;
    }
    const sku = hasVariant ? item?.variant?.sku || '' : item?.sku;
    return {
      index: i + 1, // they want to start at 1
      ...getPriceData(sku),
      item_id: sku,
      item_name: item?.name,
      item_brand: item?.brand,
      item_variant: getVariantName(item),
      item_category: item?.product_category?.[0],
      item_list_name: item?.product_type?.[0],
    };
  });
  return baseEcommerceEventStructure({ eventType, items: GtmEventItem });
}

export function mapAlgoliaSearchResultsToGtmEvent(
  eventType: GtmEventType,
  searchTerm: string,
  productIdList: string[]
) {
  const searchDetails = {
    search_term: searchTerm,
    productIds: productIdList,
  };
  return baseEventStructure(eventType, searchDetails);
}

export function mapAccountDataToGtmEvent(eventType: GtmEventType, accountDetails: any) {
  return baseEventStructure(eventType, accountDetails);
}

// should be good
export function mapDyItemsToGtmEvent(eventType: GtmEventType, cartItems: any[]) {
  const GtmEventItem = cartItems?.map((item: any, i: number) => {
    // console.log('gtm dy item pre map', item);
    const mappedItem = {
      index: i + 1, // they want to start at 1
      ...getPriceData(item?.sku),
      item_id: item?.sku,
      item_name: item?.parent_name,
      item_brand: item?.brand,
      item_variant: item?.variant_name || '',
      item_category: '', // TODO - pizza oven
      item_list_name: '', // TODO - pi accessories
    };
    // console.log('gtm dy item post map', mappedItem);
    return mappedItem;
  });
  return baseEcommerceEventStructure({ eventType, items: GtmEventItem });
}

function getPriceData(sku: string) {
  const priceInfo = useProductDataStore().getPriceBySku(sku);
  return {
    price: priceInfo?.calculated_price,
    original_price: priceInfo?.price,
  };
}

function getVariantName(item: AddToCartItem) {
  const sku = item.product.sku;
  const parentSku = item.product?.parentSku;
  const itemVariantOptions = parentSku ? useProductDataStore().getVariantOptionBySku(sku, parentSku) : {};
  const optionValues = itemVariantOptions.option_values || [];
  let variantName = '';
  // @ts-expect-error
  optionValues.forEach((option: ProductVariantOptionValue, i) => {
    variantName +=
      `${option.option_display_name}: ${option.label}` +
      (i < optionValues.length - 1 && optionValues.length > 1 ? ', ' : '');
  });
  return variantName;
}
function getBcVariantName(optionValues: Option[] = []) {
  let variantName = '';
  optionValues.forEach((option, i) => {
    if (option.name.includes('Etch')) {
      return;
    }
    variantName +=
      `${option.name}: ${option.value}` + (i < optionValues.length - 1 && optionValues.length > 1 ? ', ' : '');
  });
  return variantName;
}

/**
 * Extracts the user ID from the Google Analytics device ID.
 * @example `GA1.1.xxxxxxxxxx.xxxxxxxxxx => xxxxxxxxxx_xxxxxxxxxx`
 * @link https://support.google.com/analytics/answer/11397207
 */
export function extractGoogleAnalyticsUserIdFromCookie(gaCookie: string) {
  if (gaCookie) {
    // Remove the Google Analytics tracker from the device ID.
    const userIdParts = gaCookie.split('.').slice(-2);
    if (userIdParts.length === 2) {
      return userIdParts.join('_');
    }
  }
  return undefined;
}
