import { default as index4NgZU6OsmsMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue?macro=true";
import { default as _91page_93KnGTfXR9gwMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue?macro=true";
import { default as accountF648hxNuo8Meta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue?macro=true";
import { default as _91category_93ZQVL0Lf3ERMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue?macro=true";
import { default as cartpszgZvtdCIMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue?macro=true";
import { default as check_45balancefscnKUCn5NMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue?macro=true";
import { default as _91category_93xOMMUp7sO7Meta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue?macro=true";
import { default as indexnGnQOd6aq7Meta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue?macro=true";
import { default as _91post_93M4pQocbl4FMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue?macro=true";
import { default as indexSVpoYBLrHaMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue?macro=true";
import { default as create_45accountq9zm0e9i5mMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue?macro=true";
import { default as dealer_45locatorKE5JCCM8DjMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue?macro=true";
import { default as firestarter_45rewardsRnO2y74wy0Meta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue?macro=true";
import { default as gift_45cardY3HruvSYIwMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue?macro=true";
import { default as help_45center6CulCe2jyJMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue?macro=true";
import { default as _91slug_93fsrgBNQg13Meta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue?macro=true";
import { default as klarna_45faqqH4nUkbEmnMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue?macro=true";
import { default as _91product_93HXeGDZDl3qMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue?macro=true";
import { default as _91listing_93TBMPIHw3MlMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue?macro=true";
import { default as referralsB9VjtAUcJrMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue?macro=true";
import { default as shipping_45returnsVM5QQw5D1aMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue?macro=true";
import { default as _91slug_93AOT6LmMVqOMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue?macro=true";
import { default as _91query_93KhyJzRWcLfMeta } from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "page___en-al",
    path: "/en-al/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___e-ad",
    path: "/e-ad/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___de-at",
    path: "/de-at/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-at",
    path: "/en-at/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-by",
    path: "/en-by/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-be",
    path: "/en-be/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___nl-be",
    path: "/nl-be/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___fr-be",
    path: "/fr-be/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-ba",
    path: "/en-ba/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-bg",
    path: "/en-bg/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-hr",
    path: "/en-hr/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-cz",
    path: "/en-cz/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-dk",
    path: "/en-dk/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-ee",
    path: "/en-ee/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-fi",
    path: "/en-fi/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-fr",
    path: "/en-fr/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___fr-fr",
    path: "/fr-fr/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___de-de",
    path: "/de-de/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-de",
    path: "/en-de/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-gr",
    path: "/en-gr/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-hu",
    path: "/en-hu/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-ie",
    path: "/en-ie/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-it",
    path: "/en-it/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-lv",
    path: "/en-lv/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-li",
    path: "/en-li/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-lt",
    path: "/en-lt/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-lu",
    path: "/en-lu/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___fr-lu",
    path: "/fr-lu/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-mc",
    path: "/en-mc/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___fr-mc",
    path: "/fr-mc/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-nl",
    path: "/en-nl/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___nl-nl",
    path: "/nl-nl/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-no",
    path: "/en-no/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-pl",
    path: "/en-pl/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-pt",
    path: "/en-pt/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-ro",
    path: "/en-ro/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-cs",
    path: "/en-cs/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-sk",
    path: "/en-sk/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-si",
    path: "/en-si/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-es",
    path: "/en-es/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-se",
    path: "/en-se/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-ch",
    path: "/en-ch/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___fr-ch",
    path: "/fr-ch/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___de-ch",
    path: "/de-ch/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-gb",
    path: "/en-gb/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "page___en-us",
    path: "/en-us/:page()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/[page].vue")
  },
  {
    name: "account___en-al",
    path: "/en-al/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___e-ad",
    path: "/e-ad/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___de-at",
    path: "/de-at/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-at",
    path: "/en-at/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-by",
    path: "/en-by/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-be",
    path: "/en-be/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___nl-be",
    path: "/nl-be/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___fr-be",
    path: "/fr-be/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-ba",
    path: "/en-ba/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-bg",
    path: "/en-bg/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-hr",
    path: "/en-hr/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-cz",
    path: "/en-cz/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-dk",
    path: "/en-dk/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-ee",
    path: "/en-ee/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-fi",
    path: "/en-fi/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-fr",
    path: "/en-fr/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___fr-fr",
    path: "/fr-fr/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___de-de",
    path: "/de-de/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-de",
    path: "/en-de/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-gr",
    path: "/en-gr/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-hu",
    path: "/en-hu/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-ie",
    path: "/en-ie/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-it",
    path: "/en-it/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-lv",
    path: "/en-lv/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-li",
    path: "/en-li/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-lt",
    path: "/en-lt/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-lu",
    path: "/en-lu/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___fr-lu",
    path: "/fr-lu/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-mc",
    path: "/en-mc/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___fr-mc",
    path: "/fr-mc/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-nl",
    path: "/en-nl/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___nl-nl",
    path: "/nl-nl/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-no",
    path: "/en-no/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-pl",
    path: "/en-pl/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-pt",
    path: "/en-pt/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-ro",
    path: "/en-ro/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-cs",
    path: "/en-cs/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-sk",
    path: "/en-sk/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-si",
    path: "/en-si/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-es",
    path: "/en-es/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-se",
    path: "/en-se/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-ch",
    path: "/en-ch/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___fr-ch",
    path: "/fr-ch/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___de-ch",
    path: "/de-ch/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-gb",
    path: "/en-gb/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "account___en-us",
    path: "/en-us/account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/account.vue")
  },
  {
    name: "c-category___en-al",
    path: "/en-al/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___e-ad",
    path: "/e-ad/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___de-at",
    path: "/de-at/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-at",
    path: "/en-at/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-by",
    path: "/en-by/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-be",
    path: "/en-be/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___nl-be",
    path: "/nl-be/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___fr-be",
    path: "/fr-be/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-ba",
    path: "/en-ba/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-bg",
    path: "/en-bg/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-hr",
    path: "/en-hr/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-cz",
    path: "/en-cz/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-dk",
    path: "/en-dk/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-ee",
    path: "/en-ee/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-fi",
    path: "/en-fi/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-fr",
    path: "/en-fr/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___fr-fr",
    path: "/fr-fr/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___de-de",
    path: "/de-de/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-de",
    path: "/en-de/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-gr",
    path: "/en-gr/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-hu",
    path: "/en-hu/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-ie",
    path: "/en-ie/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-it",
    path: "/en-it/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-lv",
    path: "/en-lv/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-li",
    path: "/en-li/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-lt",
    path: "/en-lt/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-lu",
    path: "/en-lu/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___fr-lu",
    path: "/fr-lu/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-mc",
    path: "/en-mc/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___fr-mc",
    path: "/fr-mc/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-nl",
    path: "/en-nl/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___nl-nl",
    path: "/nl-nl/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-no",
    path: "/en-no/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-pl",
    path: "/en-pl/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-pt",
    path: "/en-pt/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-ro",
    path: "/en-ro/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-cs",
    path: "/en-cs/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-sk",
    path: "/en-sk/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-si",
    path: "/en-si/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-es",
    path: "/en-es/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-se",
    path: "/en-se/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-ch",
    path: "/en-ch/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___fr-ch",
    path: "/fr-ch/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___de-ch",
    path: "/de-ch/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-gb",
    path: "/en-gb/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "c-category___en-us",
    path: "/en-us/c/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/c/[category].vue")
  },
  {
    name: "cart___en-al",
    path: "/en-al/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___e-ad",
    path: "/e-ad/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___de-at",
    path: "/de-at/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-at",
    path: "/en-at/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-by",
    path: "/en-by/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-be",
    path: "/en-be/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___nl-be",
    path: "/nl-be/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___fr-be",
    path: "/fr-be/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-ba",
    path: "/en-ba/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-bg",
    path: "/en-bg/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-hr",
    path: "/en-hr/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-cz",
    path: "/en-cz/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-dk",
    path: "/en-dk/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-ee",
    path: "/en-ee/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-fi",
    path: "/en-fi/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-fr",
    path: "/en-fr/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___fr-fr",
    path: "/fr-fr/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___de-de",
    path: "/de-de/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-de",
    path: "/en-de/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-gr",
    path: "/en-gr/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-hu",
    path: "/en-hu/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-ie",
    path: "/en-ie/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-it",
    path: "/en-it/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-lv",
    path: "/en-lv/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-li",
    path: "/en-li/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-lt",
    path: "/en-lt/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-lu",
    path: "/en-lu/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___fr-lu",
    path: "/fr-lu/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-mc",
    path: "/en-mc/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___fr-mc",
    path: "/fr-mc/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-nl",
    path: "/en-nl/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___nl-nl",
    path: "/nl-nl/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-no",
    path: "/en-no/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-pl",
    path: "/en-pl/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-pt",
    path: "/en-pt/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-ro",
    path: "/en-ro/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-cs",
    path: "/en-cs/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-sk",
    path: "/en-sk/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-si",
    path: "/en-si/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-es",
    path: "/en-es/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-se",
    path: "/en-se/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-ch",
    path: "/en-ch/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___fr-ch",
    path: "/fr-ch/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___de-ch",
    path: "/de-ch/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-gb",
    path: "/en-gb/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "cart___en-us",
    path: "/en-us/cart",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/cart.vue")
  },
  {
    name: "check-balance___en-al",
    path: "/en-al/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___e-ad",
    path: "/e-ad/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___de-at",
    path: "/de-at/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-at",
    path: "/en-at/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-by",
    path: "/en-by/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-be",
    path: "/en-be/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___nl-be",
    path: "/nl-be/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___fr-be",
    path: "/fr-be/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-ba",
    path: "/en-ba/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-bg",
    path: "/en-bg/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-hr",
    path: "/en-hr/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-cz",
    path: "/en-cz/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-dk",
    path: "/en-dk/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-ee",
    path: "/en-ee/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-fi",
    path: "/en-fi/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-fr",
    path: "/en-fr/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___fr-fr",
    path: "/fr-fr/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___de-de",
    path: "/de-de/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-de",
    path: "/en-de/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-gr",
    path: "/en-gr/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-hu",
    path: "/en-hu/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-ie",
    path: "/en-ie/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-it",
    path: "/en-it/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-lv",
    path: "/en-lv/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-li",
    path: "/en-li/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-lt",
    path: "/en-lt/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-lu",
    path: "/en-lu/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___fr-lu",
    path: "/fr-lu/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-mc",
    path: "/en-mc/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___fr-mc",
    path: "/fr-mc/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-nl",
    path: "/en-nl/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___nl-nl",
    path: "/nl-nl/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-no",
    path: "/en-no/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-pl",
    path: "/en-pl/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-pt",
    path: "/en-pt/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-ro",
    path: "/en-ro/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-cs",
    path: "/en-cs/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-sk",
    path: "/en-sk/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-si",
    path: "/en-si/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-es",
    path: "/en-es/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-se",
    path: "/en-se/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-ch",
    path: "/en-ch/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___fr-ch",
    path: "/fr-ch/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___de-ch",
    path: "/de-ch/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-gb",
    path: "/en-gb/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "check-balance___en-us",
    path: "/en-us/check-balance",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/check-balance.vue")
  },
  {
    name: "community-postType-category___en-al",
    path: "/en-al/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___e-ad",
    path: "/e-ad/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___de-at",
    path: "/de-at/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-at",
    path: "/en-at/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-by",
    path: "/en-by/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-be",
    path: "/en-be/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___nl-be",
    path: "/nl-be/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___fr-be",
    path: "/fr-be/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-ba",
    path: "/en-ba/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-bg",
    path: "/en-bg/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-hr",
    path: "/en-hr/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-cz",
    path: "/en-cz/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-dk",
    path: "/en-dk/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-ee",
    path: "/en-ee/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-fi",
    path: "/en-fi/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-fr",
    path: "/en-fr/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___fr-fr",
    path: "/fr-fr/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___de-de",
    path: "/de-de/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-de",
    path: "/en-de/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-gr",
    path: "/en-gr/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-hu",
    path: "/en-hu/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-ie",
    path: "/en-ie/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-it",
    path: "/en-it/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-lv",
    path: "/en-lv/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-li",
    path: "/en-li/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-lt",
    path: "/en-lt/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-lu",
    path: "/en-lu/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___fr-lu",
    path: "/fr-lu/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-mc",
    path: "/en-mc/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___fr-mc",
    path: "/fr-mc/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-nl",
    path: "/en-nl/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___nl-nl",
    path: "/nl-nl/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-no",
    path: "/en-no/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-pl",
    path: "/en-pl/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-pt",
    path: "/en-pt/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-ro",
    path: "/en-ro/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-cs",
    path: "/en-cs/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-sk",
    path: "/en-sk/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-si",
    path: "/en-si/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-es",
    path: "/en-es/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-se",
    path: "/en-se/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-ch",
    path: "/en-ch/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___fr-ch",
    path: "/fr-ch/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___de-ch",
    path: "/de-ch/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-gb",
    path: "/en-gb/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType-category___en-us",
    path: "/en-us/community/:postType()/:category()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/[category].vue")
  },
  {
    name: "community-postType___en-al",
    path: "/en-al/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___e-ad",
    path: "/e-ad/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___de-at",
    path: "/de-at/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-at",
    path: "/en-at/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-by",
    path: "/en-by/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-be",
    path: "/en-be/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___nl-be",
    path: "/nl-be/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___fr-be",
    path: "/fr-be/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-ba",
    path: "/en-ba/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-bg",
    path: "/en-bg/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-hr",
    path: "/en-hr/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-cz",
    path: "/en-cz/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-dk",
    path: "/en-dk/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-ee",
    path: "/en-ee/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-fi",
    path: "/en-fi/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-fr",
    path: "/en-fr/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___fr-fr",
    path: "/fr-fr/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___de-de",
    path: "/de-de/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-de",
    path: "/en-de/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-gr",
    path: "/en-gr/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-hu",
    path: "/en-hu/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-ie",
    path: "/en-ie/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-it",
    path: "/en-it/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-lv",
    path: "/en-lv/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-li",
    path: "/en-li/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-lt",
    path: "/en-lt/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-lu",
    path: "/en-lu/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___fr-lu",
    path: "/fr-lu/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-mc",
    path: "/en-mc/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___fr-mc",
    path: "/fr-mc/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-nl",
    path: "/en-nl/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___nl-nl",
    path: "/nl-nl/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-no",
    path: "/en-no/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-pl",
    path: "/en-pl/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-pt",
    path: "/en-pt/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-ro",
    path: "/en-ro/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-cs",
    path: "/en-cs/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-sk",
    path: "/en-sk/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-si",
    path: "/en-si/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-es",
    path: "/en-es/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-se",
    path: "/en-se/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-ch",
    path: "/en-ch/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___fr-ch",
    path: "/fr-ch/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___de-ch",
    path: "/de-ch/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-gb",
    path: "/en-gb/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType___en-us",
    path: "/en-us/community/:postType()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/index.vue")
  },
  {
    name: "community-postType-p-post___en-al",
    path: "/en-al/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___e-ad",
    path: "/e-ad/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___de-at",
    path: "/de-at/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-at",
    path: "/en-at/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-by",
    path: "/en-by/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-be",
    path: "/en-be/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___nl-be",
    path: "/nl-be/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___fr-be",
    path: "/fr-be/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-ba",
    path: "/en-ba/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-bg",
    path: "/en-bg/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-hr",
    path: "/en-hr/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-cz",
    path: "/en-cz/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-dk",
    path: "/en-dk/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-ee",
    path: "/en-ee/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-fi",
    path: "/en-fi/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-fr",
    path: "/en-fr/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___fr-fr",
    path: "/fr-fr/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___de-de",
    path: "/de-de/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-de",
    path: "/en-de/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-gr",
    path: "/en-gr/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-hu",
    path: "/en-hu/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-ie",
    path: "/en-ie/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-it",
    path: "/en-it/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-lv",
    path: "/en-lv/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-li",
    path: "/en-li/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-lt",
    path: "/en-lt/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-lu",
    path: "/en-lu/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___fr-lu",
    path: "/fr-lu/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-mc",
    path: "/en-mc/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___fr-mc",
    path: "/fr-mc/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-nl",
    path: "/en-nl/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___nl-nl",
    path: "/nl-nl/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-no",
    path: "/en-no/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-pl",
    path: "/en-pl/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-pt",
    path: "/en-pt/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-ro",
    path: "/en-ro/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-cs",
    path: "/en-cs/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-sk",
    path: "/en-sk/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-si",
    path: "/en-si/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-es",
    path: "/en-es/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-se",
    path: "/en-se/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-ch",
    path: "/en-ch/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___fr-ch",
    path: "/fr-ch/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___de-ch",
    path: "/de-ch/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-gb",
    path: "/en-gb/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community-postType-p-post___en-us",
    path: "/en-us/community/:postType()/p/:post()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/[postType]/p/[post].vue")
  },
  {
    name: "community___en-al",
    path: "/en-al/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___e-ad",
    path: "/e-ad/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___de-at",
    path: "/de-at/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-at",
    path: "/en-at/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-by",
    path: "/en-by/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-be",
    path: "/en-be/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___nl-be",
    path: "/nl-be/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___fr-be",
    path: "/fr-be/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-ba",
    path: "/en-ba/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-bg",
    path: "/en-bg/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-hr",
    path: "/en-hr/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-cz",
    path: "/en-cz/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-dk",
    path: "/en-dk/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-ee",
    path: "/en-ee/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-fi",
    path: "/en-fi/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-fr",
    path: "/en-fr/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___fr-fr",
    path: "/fr-fr/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___de-de",
    path: "/de-de/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-de",
    path: "/en-de/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-gr",
    path: "/en-gr/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-hu",
    path: "/en-hu/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-ie",
    path: "/en-ie/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-it",
    path: "/en-it/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-lv",
    path: "/en-lv/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-li",
    path: "/en-li/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-lt",
    path: "/en-lt/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-lu",
    path: "/en-lu/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___fr-lu",
    path: "/fr-lu/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-mc",
    path: "/en-mc/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___fr-mc",
    path: "/fr-mc/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-nl",
    path: "/en-nl/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___nl-nl",
    path: "/nl-nl/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-no",
    path: "/en-no/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-pl",
    path: "/en-pl/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-pt",
    path: "/en-pt/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-ro",
    path: "/en-ro/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-cs",
    path: "/en-cs/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-sk",
    path: "/en-sk/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-si",
    path: "/en-si/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-es",
    path: "/en-es/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-se",
    path: "/en-se/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-ch",
    path: "/en-ch/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___fr-ch",
    path: "/fr-ch/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___de-ch",
    path: "/de-ch/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-gb",
    path: "/en-gb/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "community___en-us",
    path: "/en-us/community",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/community/index.vue")
  },
  {
    name: "create-account___en-al",
    path: "/en-al/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___e-ad",
    path: "/e-ad/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___de-at",
    path: "/de-at/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-at",
    path: "/en-at/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-by",
    path: "/en-by/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-be",
    path: "/en-be/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___nl-be",
    path: "/nl-be/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___fr-be",
    path: "/fr-be/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-ba",
    path: "/en-ba/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-bg",
    path: "/en-bg/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-hr",
    path: "/en-hr/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-cz",
    path: "/en-cz/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-dk",
    path: "/en-dk/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-ee",
    path: "/en-ee/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-fi",
    path: "/en-fi/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-fr",
    path: "/en-fr/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___fr-fr",
    path: "/fr-fr/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___de-de",
    path: "/de-de/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-de",
    path: "/en-de/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-gr",
    path: "/en-gr/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-hu",
    path: "/en-hu/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-ie",
    path: "/en-ie/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-it",
    path: "/en-it/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-lv",
    path: "/en-lv/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-li",
    path: "/en-li/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-lt",
    path: "/en-lt/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-lu",
    path: "/en-lu/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___fr-lu",
    path: "/fr-lu/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-mc",
    path: "/en-mc/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___fr-mc",
    path: "/fr-mc/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-nl",
    path: "/en-nl/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___nl-nl",
    path: "/nl-nl/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-no",
    path: "/en-no/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-pl",
    path: "/en-pl/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-pt",
    path: "/en-pt/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-ro",
    path: "/en-ro/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-cs",
    path: "/en-cs/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-sk",
    path: "/en-sk/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-si",
    path: "/en-si/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-es",
    path: "/en-es/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-se",
    path: "/en-se/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-ch",
    path: "/en-ch/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___fr-ch",
    path: "/fr-ch/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___de-ch",
    path: "/de-ch/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-gb",
    path: "/en-gb/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "create-account___en-us",
    path: "/en-us/create-account",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/create-account.vue")
  },
  {
    name: "dealer-locator___en-al",
    path: "/en-al/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___e-ad",
    path: "/e-ad/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___de-at",
    path: "/de-at/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-at",
    path: "/en-at/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-by",
    path: "/en-by/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-be",
    path: "/en-be/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___nl-be",
    path: "/nl-be/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___fr-be",
    path: "/fr-be/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-ba",
    path: "/en-ba/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-bg",
    path: "/en-bg/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-hr",
    path: "/en-hr/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-cz",
    path: "/en-cz/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-dk",
    path: "/en-dk/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-ee",
    path: "/en-ee/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-fi",
    path: "/en-fi/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-fr",
    path: "/en-fr/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___fr-fr",
    path: "/fr-fr/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___de-de",
    path: "/de-de/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-de",
    path: "/en-de/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-gr",
    path: "/en-gr/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-hu",
    path: "/en-hu/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-ie",
    path: "/en-ie/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-it",
    path: "/en-it/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-lv",
    path: "/en-lv/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-li",
    path: "/en-li/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-lt",
    path: "/en-lt/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-lu",
    path: "/en-lu/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___fr-lu",
    path: "/fr-lu/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-mc",
    path: "/en-mc/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___fr-mc",
    path: "/fr-mc/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-nl",
    path: "/en-nl/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___nl-nl",
    path: "/nl-nl/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-no",
    path: "/en-no/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-pl",
    path: "/en-pl/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-pt",
    path: "/en-pt/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-ro",
    path: "/en-ro/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-cs",
    path: "/en-cs/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-sk",
    path: "/en-sk/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-si",
    path: "/en-si/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-es",
    path: "/en-es/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-se",
    path: "/en-se/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-ch",
    path: "/en-ch/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___fr-ch",
    path: "/fr-ch/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___de-ch",
    path: "/de-ch/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-gb",
    path: "/en-gb/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "dealer-locator___en-us",
    path: "/en-us/dealer-locator",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/dealer-locator.vue")
  },
  {
    name: "firestarter-rewards___en-al",
    path: "/en-al/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___e-ad",
    path: "/e-ad/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___de-at",
    path: "/de-at/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-at",
    path: "/en-at/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-by",
    path: "/en-by/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-be",
    path: "/en-be/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___nl-be",
    path: "/nl-be/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___fr-be",
    path: "/fr-be/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-ba",
    path: "/en-ba/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-bg",
    path: "/en-bg/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-hr",
    path: "/en-hr/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-cz",
    path: "/en-cz/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-dk",
    path: "/en-dk/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-ee",
    path: "/en-ee/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-fi",
    path: "/en-fi/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-fr",
    path: "/en-fr/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___fr-fr",
    path: "/fr-fr/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___de-de",
    path: "/de-de/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-de",
    path: "/en-de/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-gr",
    path: "/en-gr/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-hu",
    path: "/en-hu/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-ie",
    path: "/en-ie/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-it",
    path: "/en-it/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-lv",
    path: "/en-lv/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-li",
    path: "/en-li/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-lt",
    path: "/en-lt/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-lu",
    path: "/en-lu/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___fr-lu",
    path: "/fr-lu/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-mc",
    path: "/en-mc/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___fr-mc",
    path: "/fr-mc/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-nl",
    path: "/en-nl/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___nl-nl",
    path: "/nl-nl/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-no",
    path: "/en-no/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-pl",
    path: "/en-pl/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-pt",
    path: "/en-pt/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-ro",
    path: "/en-ro/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-cs",
    path: "/en-cs/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-sk",
    path: "/en-sk/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-si",
    path: "/en-si/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-es",
    path: "/en-es/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-se",
    path: "/en-se/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-ch",
    path: "/en-ch/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___fr-ch",
    path: "/fr-ch/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___de-ch",
    path: "/de-ch/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-gb",
    path: "/en-gb/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "firestarter-rewards___en-us",
    path: "/en-us/firestarter-rewards",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/firestarter-rewards.vue")
  },
  {
    name: "gift-card___en-al",
    path: "/en-al/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___e-ad",
    path: "/e-ad/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___de-at",
    path: "/de-at/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-at",
    path: "/en-at/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-by",
    path: "/en-by/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-be",
    path: "/en-be/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___nl-be",
    path: "/nl-be/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___fr-be",
    path: "/fr-be/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-ba",
    path: "/en-ba/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-bg",
    path: "/en-bg/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-hr",
    path: "/en-hr/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-cz",
    path: "/en-cz/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-dk",
    path: "/en-dk/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-ee",
    path: "/en-ee/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-fi",
    path: "/en-fi/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-fr",
    path: "/en-fr/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___fr-fr",
    path: "/fr-fr/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___de-de",
    path: "/de-de/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-de",
    path: "/en-de/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-gr",
    path: "/en-gr/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-hu",
    path: "/en-hu/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-ie",
    path: "/en-ie/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-it",
    path: "/en-it/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-lv",
    path: "/en-lv/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-li",
    path: "/en-li/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-lt",
    path: "/en-lt/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-lu",
    path: "/en-lu/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___fr-lu",
    path: "/fr-lu/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-mc",
    path: "/en-mc/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___fr-mc",
    path: "/fr-mc/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-nl",
    path: "/en-nl/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___nl-nl",
    path: "/nl-nl/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-no",
    path: "/en-no/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-pl",
    path: "/en-pl/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-pt",
    path: "/en-pt/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-ro",
    path: "/en-ro/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-cs",
    path: "/en-cs/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-sk",
    path: "/en-sk/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-si",
    path: "/en-si/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-es",
    path: "/en-es/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-se",
    path: "/en-se/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-ch",
    path: "/en-ch/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___fr-ch",
    path: "/fr-ch/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___de-ch",
    path: "/de-ch/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-gb",
    path: "/en-gb/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "gift-card___en-us",
    path: "/en-us/gift-card",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/gift-card.vue")
  },
  {
    name: "help-center___en-al",
    path: "/en-al/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___e-ad",
    path: "/e-ad/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___de-at",
    path: "/de-at/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-at",
    path: "/en-at/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-by",
    path: "/en-by/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-be",
    path: "/en-be/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___nl-be",
    path: "/nl-be/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___fr-be",
    path: "/fr-be/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-ba",
    path: "/en-ba/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-bg",
    path: "/en-bg/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-hr",
    path: "/en-hr/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-cz",
    path: "/en-cz/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-dk",
    path: "/en-dk/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-ee",
    path: "/en-ee/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-fi",
    path: "/en-fi/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-fr",
    path: "/en-fr/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___fr-fr",
    path: "/fr-fr/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___de-de",
    path: "/de-de/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-de",
    path: "/en-de/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-gr",
    path: "/en-gr/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-hu",
    path: "/en-hu/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-ie",
    path: "/en-ie/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-it",
    path: "/en-it/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-lv",
    path: "/en-lv/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-li",
    path: "/en-li/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-lt",
    path: "/en-lt/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-lu",
    path: "/en-lu/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___fr-lu",
    path: "/fr-lu/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-mc",
    path: "/en-mc/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___fr-mc",
    path: "/fr-mc/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-nl",
    path: "/en-nl/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___nl-nl",
    path: "/nl-nl/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-no",
    path: "/en-no/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-pl",
    path: "/en-pl/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-pt",
    path: "/en-pt/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-ro",
    path: "/en-ro/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-cs",
    path: "/en-cs/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-sk",
    path: "/en-sk/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-si",
    path: "/en-si/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-es",
    path: "/en-es/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-se",
    path: "/en-se/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-ch",
    path: "/en-ch/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___fr-ch",
    path: "/fr-ch/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___de-ch",
    path: "/de-ch/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-gb",
    path: "/en-gb/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "help-center___en-us",
    path: "/en-us/help-center",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/help-center.vue")
  },
  {
    name: "hlp-slug___en-al",
    path: "/en-al/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___e-ad",
    path: "/e-ad/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___de-at",
    path: "/de-at/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-at",
    path: "/en-at/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-by",
    path: "/en-by/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-be",
    path: "/en-be/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___nl-be",
    path: "/nl-be/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___fr-be",
    path: "/fr-be/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-ba",
    path: "/en-ba/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-bg",
    path: "/en-bg/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-hr",
    path: "/en-hr/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-cz",
    path: "/en-cz/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-dk",
    path: "/en-dk/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-ee",
    path: "/en-ee/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-fi",
    path: "/en-fi/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-fr",
    path: "/en-fr/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___fr-fr",
    path: "/fr-fr/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___de-de",
    path: "/de-de/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-de",
    path: "/en-de/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-gr",
    path: "/en-gr/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-hu",
    path: "/en-hu/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-ie",
    path: "/en-ie/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-it",
    path: "/en-it/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-lv",
    path: "/en-lv/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-li",
    path: "/en-li/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-lt",
    path: "/en-lt/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-lu",
    path: "/en-lu/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___fr-lu",
    path: "/fr-lu/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-mc",
    path: "/en-mc/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___fr-mc",
    path: "/fr-mc/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-nl",
    path: "/en-nl/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___nl-nl",
    path: "/nl-nl/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-no",
    path: "/en-no/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-pl",
    path: "/en-pl/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-pt",
    path: "/en-pt/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-ro",
    path: "/en-ro/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-cs",
    path: "/en-cs/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-sk",
    path: "/en-sk/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-si",
    path: "/en-si/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-es",
    path: "/en-es/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-se",
    path: "/en-se/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-ch",
    path: "/en-ch/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___fr-ch",
    path: "/fr-ch/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___de-ch",
    path: "/de-ch/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-gb",
    path: "/en-gb/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "hlp-slug___en-us",
    path: "/en-us/hlp/:slug()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/hlp/[slug].vue")
  },
  {
    name: "index___en-al",
    path: "/en-al",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___e-ad",
    path: "/e-ad",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___de-at",
    path: "/de-at",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-at",
    path: "/en-at",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-by",
    path: "/en-by",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-be",
    path: "/en-be",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___nl-be",
    path: "/nl-be",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___fr-be",
    path: "/fr-be",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-ba",
    path: "/en-ba",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-bg",
    path: "/en-bg",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-hr",
    path: "/en-hr",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-cz",
    path: "/en-cz",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-dk",
    path: "/en-dk",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-ee",
    path: "/en-ee",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-fi",
    path: "/en-fi",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-fr",
    path: "/en-fr",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___fr-fr",
    path: "/fr-fr",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___de-de",
    path: "/de-de",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-de",
    path: "/en-de",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-gr",
    path: "/en-gr",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-hu",
    path: "/en-hu",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-ie",
    path: "/en-ie",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-it",
    path: "/en-it",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-lv",
    path: "/en-lv",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-li",
    path: "/en-li",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-lt",
    path: "/en-lt",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-lu",
    path: "/en-lu",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___fr-lu",
    path: "/fr-lu",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-mc",
    path: "/en-mc",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___fr-mc",
    path: "/fr-mc",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-nl",
    path: "/en-nl",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___nl-nl",
    path: "/nl-nl",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-no",
    path: "/en-no",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-pl",
    path: "/en-pl",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-pt",
    path: "/en-pt",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-ro",
    path: "/en-ro",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-cs",
    path: "/en-cs",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-sk",
    path: "/en-sk",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-si",
    path: "/en-si",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-es",
    path: "/en-es",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-se",
    path: "/en-se",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-ch",
    path: "/en-ch",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___fr-ch",
    path: "/fr-ch",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___de-ch",
    path: "/de-ch",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-gb",
    path: "/en-gb",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "index___en-us",
    path: "/en-us",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/index.vue")
  },
  {
    name: "klarna-faq___en-al",
    path: "/en-al/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___e-ad",
    path: "/e-ad/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___de-at",
    path: "/de-at/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-at",
    path: "/en-at/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-by",
    path: "/en-by/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-be",
    path: "/en-be/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___nl-be",
    path: "/nl-be/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___fr-be",
    path: "/fr-be/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-ba",
    path: "/en-ba/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-bg",
    path: "/en-bg/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-hr",
    path: "/en-hr/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-cz",
    path: "/en-cz/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-dk",
    path: "/en-dk/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-ee",
    path: "/en-ee/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-fi",
    path: "/en-fi/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-fr",
    path: "/en-fr/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___fr-fr",
    path: "/fr-fr/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___de-de",
    path: "/de-de/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-de",
    path: "/en-de/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-gr",
    path: "/en-gr/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-hu",
    path: "/en-hu/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-ie",
    path: "/en-ie/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-it",
    path: "/en-it/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-lv",
    path: "/en-lv/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-li",
    path: "/en-li/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-lt",
    path: "/en-lt/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-lu",
    path: "/en-lu/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___fr-lu",
    path: "/fr-lu/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-mc",
    path: "/en-mc/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___fr-mc",
    path: "/fr-mc/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-nl",
    path: "/en-nl/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___nl-nl",
    path: "/nl-nl/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-no",
    path: "/en-no/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-pl",
    path: "/en-pl/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-pt",
    path: "/en-pt/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-ro",
    path: "/en-ro/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-cs",
    path: "/en-cs/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-sk",
    path: "/en-sk/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-si",
    path: "/en-si/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-es",
    path: "/en-es/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-se",
    path: "/en-se/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-ch",
    path: "/en-ch/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___fr-ch",
    path: "/fr-ch/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___de-ch",
    path: "/de-ch/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-gb",
    path: "/en-gb/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "klarna-faq___en-us",
    path: "/en-us/klarna-faq",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/klarna-faq.vue")
  },
  {
    name: "p-product___en-al",
    path: "/en-al/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___e-ad",
    path: "/e-ad/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___de-at",
    path: "/de-at/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-at",
    path: "/en-at/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-by",
    path: "/en-by/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-be",
    path: "/en-be/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___nl-be",
    path: "/nl-be/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___fr-be",
    path: "/fr-be/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-ba",
    path: "/en-ba/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-bg",
    path: "/en-bg/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-hr",
    path: "/en-hr/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-cz",
    path: "/en-cz/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-dk",
    path: "/en-dk/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-ee",
    path: "/en-ee/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-fi",
    path: "/en-fi/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-fr",
    path: "/en-fr/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___fr-fr",
    path: "/fr-fr/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___de-de",
    path: "/de-de/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-de",
    path: "/en-de/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-gr",
    path: "/en-gr/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-hu",
    path: "/en-hu/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-ie",
    path: "/en-ie/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-it",
    path: "/en-it/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-lv",
    path: "/en-lv/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-li",
    path: "/en-li/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-lt",
    path: "/en-lt/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-lu",
    path: "/en-lu/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___fr-lu",
    path: "/fr-lu/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-mc",
    path: "/en-mc/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___fr-mc",
    path: "/fr-mc/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-nl",
    path: "/en-nl/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___nl-nl",
    path: "/nl-nl/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-no",
    path: "/en-no/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-pl",
    path: "/en-pl/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-pt",
    path: "/en-pt/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-ro",
    path: "/en-ro/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-cs",
    path: "/en-cs/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-sk",
    path: "/en-sk/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-si",
    path: "/en-si/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-es",
    path: "/en-es/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-se",
    path: "/en-se/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-ch",
    path: "/en-ch/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___fr-ch",
    path: "/fr-ch/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___de-ch",
    path: "/de-ch/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-gb",
    path: "/en-gb/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "p-product___en-us",
    path: "/en-us/p/:product()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/p/[product].vue")
  },
  {
    name: "plp-listing___en-al",
    path: "/en-al/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___e-ad",
    path: "/e-ad/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___de-at",
    path: "/de-at/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-at",
    path: "/en-at/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-by",
    path: "/en-by/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-be",
    path: "/en-be/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___nl-be",
    path: "/nl-be/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___fr-be",
    path: "/fr-be/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-ba",
    path: "/en-ba/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-bg",
    path: "/en-bg/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-hr",
    path: "/en-hr/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-cz",
    path: "/en-cz/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-dk",
    path: "/en-dk/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-ee",
    path: "/en-ee/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-fi",
    path: "/en-fi/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-fr",
    path: "/en-fr/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___fr-fr",
    path: "/fr-fr/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___de-de",
    path: "/de-de/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-de",
    path: "/en-de/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-gr",
    path: "/en-gr/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-hu",
    path: "/en-hu/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-ie",
    path: "/en-ie/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-it",
    path: "/en-it/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-lv",
    path: "/en-lv/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-li",
    path: "/en-li/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-lt",
    path: "/en-lt/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-lu",
    path: "/en-lu/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___fr-lu",
    path: "/fr-lu/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-mc",
    path: "/en-mc/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___fr-mc",
    path: "/fr-mc/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-nl",
    path: "/en-nl/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___nl-nl",
    path: "/nl-nl/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-no",
    path: "/en-no/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-pl",
    path: "/en-pl/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-pt",
    path: "/en-pt/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-ro",
    path: "/en-ro/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-cs",
    path: "/en-cs/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-sk",
    path: "/en-sk/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-si",
    path: "/en-si/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-es",
    path: "/en-es/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-se",
    path: "/en-se/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-ch",
    path: "/en-ch/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___fr-ch",
    path: "/fr-ch/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___de-ch",
    path: "/de-ch/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-gb",
    path: "/en-gb/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "plp-listing___en-us",
    path: "/en-us/plp/:listing()",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/plp/[listing].vue")
  },
  {
    name: "referrals___en-al",
    path: "/en-al/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___e-ad",
    path: "/e-ad/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___de-at",
    path: "/de-at/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-at",
    path: "/en-at/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-by",
    path: "/en-by/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-be",
    path: "/en-be/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___nl-be",
    path: "/nl-be/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___fr-be",
    path: "/fr-be/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-ba",
    path: "/en-ba/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-bg",
    path: "/en-bg/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-hr",
    path: "/en-hr/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-cz",
    path: "/en-cz/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-dk",
    path: "/en-dk/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-ee",
    path: "/en-ee/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-fi",
    path: "/en-fi/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-fr",
    path: "/en-fr/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___fr-fr",
    path: "/fr-fr/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___de-de",
    path: "/de-de/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-de",
    path: "/en-de/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-gr",
    path: "/en-gr/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-hu",
    path: "/en-hu/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-ie",
    path: "/en-ie/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-it",
    path: "/en-it/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-lv",
    path: "/en-lv/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-li",
    path: "/en-li/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-lt",
    path: "/en-lt/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-lu",
    path: "/en-lu/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___fr-lu",
    path: "/fr-lu/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-mc",
    path: "/en-mc/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___fr-mc",
    path: "/fr-mc/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-nl",
    path: "/en-nl/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___nl-nl",
    path: "/nl-nl/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-no",
    path: "/en-no/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-pl",
    path: "/en-pl/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-pt",
    path: "/en-pt/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-ro",
    path: "/en-ro/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-cs",
    path: "/en-cs/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-sk",
    path: "/en-sk/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-si",
    path: "/en-si/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-es",
    path: "/en-es/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-se",
    path: "/en-se/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-ch",
    path: "/en-ch/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___fr-ch",
    path: "/fr-ch/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___de-ch",
    path: "/de-ch/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-gb",
    path: "/en-gb/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "referrals___en-us",
    path: "/en-us/referrals",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/referrals.vue")
  },
  {
    name: "shipping-returns___en-al",
    path: "/en-al/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___e-ad",
    path: "/e-ad/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___de-at",
    path: "/de-at/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-at",
    path: "/en-at/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-by",
    path: "/en-by/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-be",
    path: "/en-be/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___nl-be",
    path: "/nl-be/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___fr-be",
    path: "/fr-be/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-ba",
    path: "/en-ba/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-bg",
    path: "/en-bg/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-hr",
    path: "/en-hr/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-cz",
    path: "/en-cz/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-dk",
    path: "/en-dk/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-ee",
    path: "/en-ee/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-fi",
    path: "/en-fi/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-fr",
    path: "/en-fr/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___fr-fr",
    path: "/fr-fr/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___de-de",
    path: "/de-de/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-de",
    path: "/en-de/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-gr",
    path: "/en-gr/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-hu",
    path: "/en-hu/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-ie",
    path: "/en-ie/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-it",
    path: "/en-it/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-lv",
    path: "/en-lv/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-li",
    path: "/en-li/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-lt",
    path: "/en-lt/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-lu",
    path: "/en-lu/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___fr-lu",
    path: "/fr-lu/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-mc",
    path: "/en-mc/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___fr-mc",
    path: "/fr-mc/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-nl",
    path: "/en-nl/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___nl-nl",
    path: "/nl-nl/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-no",
    path: "/en-no/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-pl",
    path: "/en-pl/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-pt",
    path: "/en-pt/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-ro",
    path: "/en-ro/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-cs",
    path: "/en-cs/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-sk",
    path: "/en-sk/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-si",
    path: "/en-si/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-es",
    path: "/en-es/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-se",
    path: "/en-se/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-ch",
    path: "/en-ch/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___fr-ch",
    path: "/fr-ch/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___de-ch",
    path: "/de-ch/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-gb",
    path: "/en-gb/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "shipping-returns___en-us",
    path: "/en-us/shipping-returns",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/shipping-returns.vue")
  },
  {
    name: "sp-slug___en-al",
    path: "/en-al/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___e-ad",
    path: "/e-ad/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___de-at",
    path: "/de-at/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-at",
    path: "/en-at/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-by",
    path: "/en-by/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-be",
    path: "/en-be/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___nl-be",
    path: "/nl-be/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___fr-be",
    path: "/fr-be/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-ba",
    path: "/en-ba/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-bg",
    path: "/en-bg/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-hr",
    path: "/en-hr/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-cz",
    path: "/en-cz/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-dk",
    path: "/en-dk/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-ee",
    path: "/en-ee/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-fi",
    path: "/en-fi/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-fr",
    path: "/en-fr/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___fr-fr",
    path: "/fr-fr/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___de-de",
    path: "/de-de/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-de",
    path: "/en-de/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-gr",
    path: "/en-gr/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-hu",
    path: "/en-hu/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-ie",
    path: "/en-ie/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-it",
    path: "/en-it/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-lv",
    path: "/en-lv/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-li",
    path: "/en-li/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-lt",
    path: "/en-lt/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-lu",
    path: "/en-lu/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___fr-lu",
    path: "/fr-lu/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-mc",
    path: "/en-mc/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___fr-mc",
    path: "/fr-mc/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-nl",
    path: "/en-nl/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___nl-nl",
    path: "/nl-nl/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-no",
    path: "/en-no/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-pl",
    path: "/en-pl/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-pt",
    path: "/en-pt/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-ro",
    path: "/en-ro/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-cs",
    path: "/en-cs/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-sk",
    path: "/en-sk/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-si",
    path: "/en-si/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-es",
    path: "/en-es/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-se",
    path: "/en-se/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-ch",
    path: "/en-ch/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___fr-ch",
    path: "/fr-ch/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___de-ch",
    path: "/de-ch/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-gb",
    path: "/en-gb/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "sp-slug___en-us",
    path: "/en-us/sp/:slug()",
    meta: _91slug_93AOT6LmMVqOMeta || {},
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/sp/[slug].vue")
  },
  {
    name: "search-page-with-query___en-al",
    path: "/en-al/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___e-ad",
    path: "/e-ad/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___de-at",
    path: "/de-at/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-at",
    path: "/en-at/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-by",
    path: "/en-by/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-be",
    path: "/en-be/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___nl-be",
    path: "/nl-be/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___fr-be",
    path: "/fr-be/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-ba",
    path: "/en-ba/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-bg",
    path: "/en-bg/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-hr",
    path: "/en-hr/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-cz",
    path: "/en-cz/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-dk",
    path: "/en-dk/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-ee",
    path: "/en-ee/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-fi",
    path: "/en-fi/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-fr",
    path: "/en-fr/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___fr-fr",
    path: "/fr-fr/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___de-de",
    path: "/de-de/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-de",
    path: "/en-de/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-gr",
    path: "/en-gr/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-hu",
    path: "/en-hu/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-ie",
    path: "/en-ie/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-it",
    path: "/en-it/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-lv",
    path: "/en-lv/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-li",
    path: "/en-li/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-lt",
    path: "/en-lt/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-lu",
    path: "/en-lu/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___fr-lu",
    path: "/fr-lu/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-mc",
    path: "/en-mc/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___fr-mc",
    path: "/fr-mc/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-nl",
    path: "/en-nl/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___nl-nl",
    path: "/nl-nl/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-no",
    path: "/en-no/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-pl",
    path: "/en-pl/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-pt",
    path: "/en-pt/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-ro",
    path: "/en-ro/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-cs",
    path: "/en-cs/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-sk",
    path: "/en-sk/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-si",
    path: "/en-si/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-es",
    path: "/en-es/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-se",
    path: "/en-se/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-ch",
    path: "/en-ch/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___fr-ch",
    path: "/fr-ch/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___de-ch",
    path: "/de-ch/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-gb",
    path: "/en-gb/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page-with-query___en-us",
    path: "/en-us/search?q=:query",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-al",
    path: "/en-al/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___e-ad",
    path: "/e-ad/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___de-at",
    path: "/de-at/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-at",
    path: "/en-at/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-by",
    path: "/en-by/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-be",
    path: "/en-be/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___nl-be",
    path: "/nl-be/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___fr-be",
    path: "/fr-be/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-ba",
    path: "/en-ba/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-bg",
    path: "/en-bg/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-hr",
    path: "/en-hr/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-cz",
    path: "/en-cz/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-dk",
    path: "/en-dk/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-ee",
    path: "/en-ee/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-fi",
    path: "/en-fi/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-fr",
    path: "/en-fr/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___fr-fr",
    path: "/fr-fr/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___de-de",
    path: "/de-de/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-de",
    path: "/en-de/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-gr",
    path: "/en-gr/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-hu",
    path: "/en-hu/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-ie",
    path: "/en-ie/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-it",
    path: "/en-it/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-lv",
    path: "/en-lv/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-li",
    path: "/en-li/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-lt",
    path: "/en-lt/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-lu",
    path: "/en-lu/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___fr-lu",
    path: "/fr-lu/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-mc",
    path: "/en-mc/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___fr-mc",
    path: "/fr-mc/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-nl",
    path: "/en-nl/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___nl-nl",
    path: "/nl-nl/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-no",
    path: "/en-no/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-pl",
    path: "/en-pl/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-pt",
    path: "/en-pt/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-ro",
    path: "/en-ro/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-cs",
    path: "/en-cs/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-sk",
    path: "/en-sk/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-si",
    path: "/en-si/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-es",
    path: "/en-es/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-se",
    path: "/en-se/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-ch",
    path: "/en-ch/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___fr-ch",
    path: "/fr-ch/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___de-ch",
    path: "/de-ch/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-gb",
    path: "/en-gb/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  },
  {
    name: "search-page___en-us",
    path: "/en-us/search",
    component: () => import("/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/pages/search/[query].vue")
  }
]