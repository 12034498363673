import { extractRegionalEnvs, regionHeaderName, localeHeaderName } from '../../utils/region-handler';

export function useEnvApiSettings() {
  const regionLocaleProperties = useRegionLocaleProperties();
  const config = useRuntimeConfig();
  const { regionEnvs } = config;
  const { regionEnvs: publicRegionEnvs } = config.public;

  const regionalConfig = extractRegionalEnvs({
    // @ts-expect-error
    regionEnvs: {
      ...regionEnvs,
      ...publicRegionEnvs,
    },
    regionCode: regionLocaleProperties.value.regionCode || '',
  });
  return regionalConfig;
}

export function useRegionRequestSettings() {
  const regionLocaleProperties = useRegionLocaleProperties();

  const regionRequestOptions = {
    regionLocaleProperties,
    regionHeaderName,
    localeHeaderName,
  };

  return regionRequestOptions;
}
