import { useDyStore } from '~/store/dy';

export default defineNuxtRouteMiddleware(async (middleware) => {
  const nuxtApp = useNuxtApp();

  if (nuxtApp.isHydrating) {
    return;
  }

  if (process.client) {
    const langCode = middleware.path.split('/')[1];
    const dyStore = useDyStore();
    await dyStore.updateDyChoices(langCode);
  }
});
