export interface DeviceState {
  device?: ReturnType<typeof useDevice>;
}

export const useDeviceStore = defineStore('device', {
  state: (): DeviceState => ({
    device: undefined,
  }),

  actions: {
    setDevice(device: DeviceState['device']) {
      this.device = device;
    },
  },

  getters: {
    isDesktop(state) {
      return state?.device?.isDesktop === true;
    },
    isTablet(state) {
      return state?.device?.isTablet === true;
    },
    isMobileOrTablet(state) {
      return state?.device?.isMobileOrTablet === true;
    },
    isMobile(state) {
      return state?.device?.isMobile === true;
    },
  },
});
