import { H3Event } from 'h3';
import { find, flatten, get } from 'lodash-es';

/* eslint-disable camelcase */
type Promotion = {
  id: number;
  name: string;
  created_from: string;
  customer: {
    group_ids: number[];
    minimum_order_count: number;
    excluded_group_ids: number[];
    segments: unknown;
  };
  rules: [
    {
      action: {
        fixed_price_set?: {
          quantity: number;
          fixed_price: number;
          strategy: string;
          exclude_items_on_sale: boolean;
          items: {
            products: number[];
          };
          products?: any[];
        };
        cart_items?: {
          add_free_item: boolean;
          as_total: boolean;
          discount: {
            percentage_amount: string;
          };
          exclude_items_on_sale: boolean;
          include_items_considered_by_condition: boolean;
          items: {
            products: number[];
          };
          quantity: number;
          strategy: string;
          products?: any[];
        };
      };
      apply_once: boolean;
      stop: boolean;
      condition: {
        cart: {
          minimum_spend: string;
        };
      };
    }
  ];
};

export interface GiftsState {
  promotion: Promotion | null;
  claimMessage: string;
  unlockMessage: string;
  products: any[];
}

export const useGiftsStore = defineStore('gifts', {
  state: (): GiftsState => ({
    promotion: null,
    claimMessage: '',
    unlockMessage: '',
    products: [],
  }),

  actions: {
    setPromotion(promotion: GiftsState['promotion']) {
      this.promotion = promotion;
    },
    setClaimMessage(claimMessage: GiftsState['claimMessage']) {
      this.claimMessage = claimMessage;
    },
    setUnlockMessage(unlockMessage: GiftsState['unlockMessage']) {
      this.unlockMessage = unlockMessage;
    },
    setProducts(products: GiftsState['products']) {
      this.products = products;
    },
    setInitialData({ promotion, claimMessage, unlockMessage, products }: GiftsState) {
      this.setPromotion(promotion);
      this.setClaimMessage(claimMessage);
      this.setUnlockMessage(unlockMessage);
      this.setProducts(products);
    },
    async getPromotion({
      event = undefined,
      query,
    }: {
      event?: H3Event;
      query?: {
        locale?: string;
        region?: string;
      };
    }): Promise<GiftsState> {
      const $cfFetch = cfFetchBuilder(event);
      let url = `/api/contentstack/product-gwp`;
      if (query?.locale) {
        url += `?locale=${query.locale}`;
      }
      const res = await $cfFetch(url);
      // @ts-expect-error
      const { bc_promotion, claim_message, unlock_message } = res?.data?.all_product_gwp?.items[0] || {};
      const rules: Promotion['rules'] = bc_promotion?.rules || [];
      const gwpRules = rules.map((rule) => {
        // console.log('rule', rule)
        const promo = rule?.action?.fixed_price_set || rule?.action?.cart_items;
        const quantity = get(promo, 'quantity');
        const productIds = get(promo, 'items.products') || [];
        let price: any = get(promo, 'fixed_price');
        price = price ? parseFloat(price.replace(/,/g, '.')).toFixed(2) : price;
        let minimumSpend: number | string | undefined = get(rule, 'condition.cart.minimum_spend');
        minimumSpend = minimumSpend ? parseInt(minimumSpend) : undefined;
        return {
          quantity,
          price,
          productIds,
          minimumSpend,
        };
      });
      const productIds = gwpRules.map((rule) => rule.productIds);
      const ids = flatten(productIds).toString();

      const productsRes = await $fetch(
        `/api/contentstack/products-in?ids=${ids}&locale=${query?.locale}&region=${query?.region}`,
        {
          method: 'GET',
        }
      );
      const products = get(productsRes, 'data.all_product_v2.items') || [];

      const mapped = gwpRules.map((rule: any) => {
        const product = find(products, (product: any) => {
          const gwpIds = rule?.productIds || [];
          return gwpIds.includes(product?.product_offerings?.bc_primary_product?.product?.id);
        });
        return {
          product,
          ...(typeof rule === 'object' && { ...rule }),
        };
      });

      const lowestThresholdSort = mapped.sort((giftA: any, giftB: any) => {
        return giftA?.minimumSpend - giftB?.minimumSpend;
      });

      return {
        promotion: bc_promotion,
        claimMessage: claim_message,
        unlockMessage: unlock_message,
        products: lowestThresholdSort,
      };
    },
  },
});
