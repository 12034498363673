/* eslint-disable */

// WARNING: This file is used in /api - outside of Nuxt
// import { type fetch as cfFetch, type Response } from '@cloudflare/workers-types';
import { getQuery, type H3Event } from 'h3';
import { RuntimeConfig } from 'nuxt/schema';
import { NApiCTSContext, NApiContext } from '../api/contentstack/[...contentstack]';
import { headerQuery } from './graphql/contentstack/header';
import { footerQuery } from './graphql/contentstack/footer';
import { homeQuery } from './graphql/contentstack/home';
import { brandSettingsQuery } from './graphql/contentstack/brand-settings';
import { algoliaSettingsQuery } from './graphql/contentstack/algolia-settings';
import { featureFlagQuery } from './graphql/contentstack/feature-flag';
import { regionsLaunchedQuery } from './graphql/contentstack/regions-launched';
import { pageQuery } from './graphql/contentstack/page';
import {
  postQuery,
  postsByCatUrlPostType,
  allBlogs,
  getCategory,
  communityLP,
  communityLPSections,
} from './graphql/contentstack/community';
import {
  productQuery,
  productsByBCIdQuery,
  filterByBCIdQuery,
  productQuerySections,
  productIdsQuery,
} from './graphql/contentstack/product';
import { clpQuery } from './graphql/contentstack/clp';
import { plpQuery } from './graphql/contentstack/plp';
import { spQuery, spQuerySections } from './graphql/contentstack/splashPage';
import { hlpQuery } from './graphql/contentstack/hlp';
import { compactGraphQLQuery } from './compress';
import { gwpQuery } from './graphql/contentstack/product-gwp';
import type { GraphQLReturnType } from './graphql/contentstack/graphql-return-type';

export type QueryObject = ReturnType<typeof getQuery>;
// @ts-expect-error
export interface CtsQueryParams extends QueryObject {
  bcId?: string;
  cat?: string;
  ids?: string;
  limit?: string;
  locale?: string;
  postType?: string;
  regionCode?: string;
  url?: string;
}

export type pageQuery = {
  pageQuery: typeof pageQuery;
  productQuery: typeof productQuery;
  productQuerySections: typeof productQuerySections;
  productIdsQuery: typeof productIdsQuery;
  clpQuery: typeof clpQuery;
  plpQuery: typeof plpQuery;
  postQuery: typeof postQuery;
  spQuery: typeof spQuery;
  spQuerySections: typeof spQuerySections;
  hlpQuery: typeof hlpQuery;
};

export type CtsEnvVars = {
  cdaToken: string;
  ctsApiKey: string;
  environment: string;
  ctsApiBranch: string;
  cacheMaxAge: string;
  cacheStale: string;
  cacheExtraValues: string;
  cacheClearSiteData: string;
  cacheTags: string;
  devCacheBust: string;
  loggingEnabled: boolean;
};

export enum QueryTypes {
  page = 'pageQuery',
  product = 'productQuery',
  productSections = 'productQuerySections',
  productIds = 'productIdsQuery',
  postQuery = 'postQuery',
  clp = 'clpQuery',
  plp = 'plpQuery',
  sp = 'spQuery',
  sps = 'spQuerySections',
  hlp = 'hlpQuery',
}

export const createNewEnv = (config: RuntimeConfig): CtsEnvVars => {
  // @ts-expect-error
  return {
    environment: config.public.environment,
    ctsApiKey: config.ctsApiKey,
    cdaToken: config.cdaToken,
    ctsApiBranch: config.public.ctsApiBranch,
    cacheMaxAge: config.public.cacheMaxAge,
    cacheStale: config.public.cacheStale,
    cacheExtraValues: config.public.cacheExtraValues,
    cacheClearSiteData: config.public.cacheClearSiteData,
    cacheTags: config.public.cacheTags,
    devCacheBust: config.public.devCacheBust,
  };
};

/**
 * Collect tags for the environment and query parameters.
 */
function getBaseTags(env: CtsEnvVars, query: CtsQueryParams) {
  const tags = [`env:${env.environment}`, `${env.environment}-solo-caches`, `contentstack:${env.ctsApiKey}`];

  if (query.locale) {
    const lang = query.locale.split('-')[0];

    if (lang) {
      tags.push(`lang:${lang}`);
    }

    tags.push(`locale:${query.locale}`);
  }

  return tags;
}

/**
 * Hash the given string.
 * @link https://developers.cloudflare.com/workers/examples/cache-post-request/
 */
async function sha256(message: string) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  return [...new Uint8Array(hashBuffer)].map((b) => b.toString(16).padStart(2, '0')).join('');
}

/**
 * Returns a cache key given the request parameters.
 * @link https://developers.cloudflare.com/workers/examples/cache-post-request/
 */
async function fetchCacheKey(url: URL, body: string) {
  const hash = await sha256(body);
  const cacheUrl = new URL(url);

  cacheUrl.pathname = `${cacheUrl.pathname}/${hash}/0`;

  return cacheUrl.toString();
}

/**
 * Extract headers to a plain object for debugging.
 */
function extractHeaders(response: Response) {
  const result: Record<string, string> = {};

  // API-level types causes this to fail `tsc`.
  // TODO: The API should not be checking these types,
  //       but we're stuck with it for now.
  // @ts-ignore
  for (const [key, value] of response.headers.entries()) {
    result[key] = value;
  }

  return result;
}

/**
 * Query types.
 */
type ContentstackQueryType = (
  | 'algolia-settings'
  | 'all-posts'
  | 'brand-settings'
  | 'clpQuery'
  | 'community-category'
  | 'community-post-cat-post'
  | 'communityLP'
  | 'communityLPSections'
  | 'ctsProductByBcId'
  | 'feature-flag'
  | 'footer'
  | 'header'
  | 'hlpQuery'
  | 'home'
  | 'pageQuery'
  | 'plpQuery'
  | 'postQuery'
  | 'product-gwp'
  | 'productIdsQuery'
  | 'productQuery'
  | 'productQuerySections'
  | 'products-in'
  | 'regions-launched'
  | 'search-page-settings'
  | 'spQuery'
  | 'spQuerySections'
);

/**
 * Query map.
 */
const handlers: Record<
  ContentstackQueryType,
  (query: CtsQueryParams) => ({
    graphql: GraphQLReturnType;
    tags: string[];
  })
> = {
  'algolia-settings': query => ({
    graphql: algoliaSettingsQuery('Global Algolia Settings', query),
    tags: ['nuxt:api:contentstack:brand-settings'],
  }),
  'all-posts': query => ({
    graphql: allBlogs(query),
    // TODO: verify `postType` is normalized
    tags: [`nuxt:api:contentstack:posts:${query.postType ?? 'blog'}`],
  }),
  'brand-settings': query => ({
    graphql: brandSettingsQuery(query),
    tags: ['nuxt:api:contentstack:brand-settings'],
  }),
  clpQuery: query => ({
    graphql: clpQuery(query),
    // TODO: tags?
    tags: [],
  }),
  'community-category': query => ({
    graphql: getCategory(query),
    // TODO: tags?
    tags: [],
  }),
  'community-post-cat-post': query => ({
    graphql: postsByCatUrlPostType(query),
    // TODO: tags?
    tags: [],
  }),
  communityLP: query => ({
    graphql: communityLP(query),
    // TODO: tags?
    tags: [],
  }),
  communityLPSections: query => ({
    graphql: communityLPSections(query),
    // TODO: tags?
    tags: [],
  }),
  ctsProductByBcId: query => ({
    // TODO: fix this query
    graphql: filterByBCIdQuery(query),
    // TODO: tags?
    tags: [],
  }),
  'feature-flag': query => ({
    graphql: featureFlagQuery(query),
    // TODO: tags?
    tags: [],
  }),
  footer: query => ({
    graphql: footerQuery(query),
    tags: ['nuxt:api:contentstack:footer'],
  }),
  header: query => ({
    graphql: headerQuery(query),
    tags: ['nuxt:api:contentstack:header'],
  }),
  hlpQuery: query => ({
    graphql: hlpQuery(query),
    // TODO: tags?
    tags: [],
  }),
  home: query => ({
    graphql: homeQuery(query),
    tags: ['nuxt:api:contentstack:home'],
  }),
  pageQuery: query => ({
    graphql: pageQuery(query),
    // TODO: tags?
    tags: [],
  }),
  plpQuery: query => ({
    graphql: plpQuery(query),
    // TODO: tags?
    tags: [],
  }),
  postQuery: query => ({
    graphql: postQuery(query),
    // TODO: tags?
    tags: [],
  }),
  'product-gwp': query => ({
    graphql: gwpQuery(query),
    // TODO: tags?
    tags: [],
  }),
  productIdsQuery: query => ({
    graphql: productIdsQuery(query),
    tags: [`nuxt:api:contentstack:product:${query.url?.replace(/^\//, '').replace(/\//g, '-')}`],
  }),
  productQuery: query => ({
    graphql: productQuery(query),
    tags: [`nuxt:api:contentstack:product:${query.url?.replace(/^\//, '').replace(/\//g, '-')}`],
  }),
  productQuerySections: query => ({
    graphql: productQuerySections(query),
    tags: [`nuxt:api:contentstack:product:${query.url?.replace(/^\//, '').replace(/\//g, '-')}`],
  }),
  'products-in': query => ({
    graphql: productsByBCIdQuery(query),
    // TODO: tags?
    tags: [],
  }),
  'regions-launched': query => ({
    graphql: regionsLaunchedQuery(query),
    // TODO: tags?
    tags: [],
  }),
  'search-page-settings': query => ({
    graphql: algoliaSettingsQuery('Search Page Algolia', query),
    tags: ['nuxt:api:contentstack:search-page-settings'],
  }),
  spQuery: query => ({
    graphql: spQuery(query),
    // TODO: tags?
    tags: [],
  }),
  spQuerySections: query => ({
    graphql: spQuerySections(query),
    // TODO: tags?
    tags: [],
  }),
};

/**
 * Type guard for `ContentstackQueryType`.
 */
function isValidContentstackQueryType(type: any): type is ContentstackQueryType {
  return typeof type === 'string' && Object.keys(handlers).includes(type);
}

/**
 * Return type for Contentstack fetch.
 */
interface ContentstackFetchReturnType {
  body: string;
  cacheHit: boolean;
  cacheKey: string;
  cacheTags: string[];
  originResponseHeaders: Record<string, string>;
  response: Response;
};

/**
 * Make a fetch request to the Contentstack API with
 * relevant Cloudflare cache parameters.
 */
async function fetchFromContentstack(
  env: CtsEnvVars,
  query: CtsQueryParams,
  id: ContentstackQueryType,
): Promise<ContentstackFetchReturnType> {
  const handler = handlers[id];
  const { graphql, tags } = handler(query);
  const headers = new Headers();

  headers.set('access_token', env.cdaToken);
  headers.set('Content-Type', 'application/json');

  if (env.ctsApiBranch) {
    headers.set('branch', env.ctsApiBranch);
  }

  const body = JSON.stringify({
    query: compactGraphQLQuery(graphql.query),
    variables: graphql.variables,
  });

  const textEncoder = new TextEncoder();
  const size = textEncoder.encode(body).length;

  if (size >= 8192) {
    console.warn(body);
    throw new Error(`request body is too large for Contentstack, ensure it's less than 8192 bytes`);
  }

  const url = new URL('https://graphql.contentstack.com');

  url.pathname = `/stacks/${env.ctsApiKey}`;
  url.searchParams.set('environment', env.environment);

  const cacheTags = [...new Set([...getBaseTags(env, query), ...tags])];
  const cacheKey = await fetchCacheKey(url, body);
  let originResponseHeaders: Record<string, string> = {};
  let response = await caches.default.match(cacheKey);
  let cacheHit = true;

  if (!response) {
    cacheHit = false;

    response = await fetch(url, {
      body,
      headers,
      method: 'POST',
    });

    // Extract headers before modifying them
    originResponseHeaders = extractHeaders(response);

    response = new Response(response.body, response);

    // Cloudflare uses the `Cache-Control` and other headers
    // to determine how long to keep the generated response
    // around in the cache, what to tag it with, etc. These
    // are not sent down to the client, they're sent to
    // Cloudflare in the response being stored via the Cache
    // API.

    response.headers.delete('Cache-Control');
    response.headers.append('Cache-Control', `public`);
    response.headers.append('Cache-Control', `max-age=86400`);
    response.headers.append('Cache-Control', `s-maxage=86400`);
    response.headers.append('Cache-Control', `stale-if-error=3600`);
    response.headers.append('Cache-Control', `stale-while-revalidate=3600`);

    // TODO: Validate the reason this is here, does Cloudflare
    //       respect this header, and is it necessary?
    if (env?.cacheClearSiteData) {
      response.headers.append('Clear-Site-Data', env.cacheClearSiteData);
    }

    response.headers.append('Cache-Tag', cacheTags.join(','));
    response.headers.append('X-Solo-Cache-Time', new Date().toISOString());
  }

  return {
    body,
    cacheHit,
    cacheKey,
    cacheTags,
    originResponseHeaders,
    response,
  };
}

/**
 * 
 */
async function applyContentstackResultToEvent(
  env: CtsEnvVars,
  event: H3Event,
  {
    body,
    cacheHit,
    cacheKey,
    cacheTags,
    originResponseHeaders,
    response,
  }: ContentstackFetchReturnType,
) {
  // Cache the response if it's new.

  if (!cacheHit && response.status >= 200 && response.status < 300) {
    event.waitUntil(caches.default.put(cacheKey, response.clone()));
  }

  // Set some headers to give us visibility into the cache
  // behavior. These are sent to the client along with the
  // cached response JSON.

  appendResponseHeader(event, 'X-Solo-Age', response.headers.get('Age') ?? 'UNAVAILABLE');
  appendResponseHeader(event, 'X-Solo-Cache-Control', response.headers.get('Cache-Control') ?? 'UNAVAILABLE');
  appendResponseHeader(event, 'X-Solo-Cache-Key', cacheKey);
  appendResponseHeader(event, 'X-Solo-Cache-Status', response.headers.get('CF-Cache-Status') ?? 'UNAVAILABLE');
  appendResponseHeader(event, 'X-Solo-Cache-Tag', cacheTags.join(','));
  appendResponseHeader(event, 'X-Solo-Cache-Time', response.headers.get('X-Solo-Cache-Time') ?? 'UNAVAILABLE');

  if (env.environment !== 'production') {
    appendResponseHeader(event, 'X-Solo-Contentstack', JSON.stringify(originResponseHeaders));
    appendResponseHeader(event, 'X-Solo-GraphQL', body);
  }

  return await response.json();
}

export { applyContentstackResultToEvent, fetchFromContentstack, isValidContentstackQueryType };
export type { ContentstackFetchReturnType };
